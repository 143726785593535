import {Cell, Column, HeaderGroup, TableInstance, useTable} from "react-table";

interface CommonReactTableProps {
    columns: Column[],
    data: object[],
    tableId: string,
    tHeadClassName?: string,
    getHeaderProps: (column: Column) => object,
    getCellProps: (column: Cell) => object
}

interface CommonHeaderGroup extends HeaderGroup {
    headerClassName?: string,
    cellClassName?: string
}

export function CommonReactTable({columns, data, tableId, tHeadClassName, getHeaderProps, getCellProps}: CommonReactTableProps) {
    const tableInstance: TableInstance = useTable({columns, data});

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance;

    return (
        <table id={tableId} {...getTableProps([
            {
                style: {
                    width: tableInstance.columns[0].width+"px",
                }
            }
        ])}>
            <thead className={tHeadClassName ?? "operatorDaysHead"}>
            {
                headerGroups.map((headerGroup )=> {
                    return (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column:CommonHeaderGroup ) => {
                                return (
                                    <th {...column.getHeaderProps(getHeaderProps(column))}>
                                        { column.render('Header') }
                                    </th>
                                )
                            })}
                        </tr>
                    )
                })
            }
            </thead>
            <tbody {...getTableBodyProps()}>
            { rows.map((row) => {
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map((cell: Cell) => {
                            return (
                                <td {...cell.getCellProps(getCellProps(cell))}>
                                    {cell.render('Cell')}
                                </td>
                            )
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>

    )
}