import {createContext, useEffect} from "react";
import PlanningReducer from "./reducer";
import {initialState, PlanningState} from "./state";
import {createActions, PlanningActions} from "./actions";
import useAsyncReducer from "../../Generic/hooks/asyncReducer";


export let PlanningContext = createContext<{state: PlanningState, actions: PlanningActions, error: Error|null}>(
    {
        state: initialState(),
        actions: createActions(),
        error: null

    });

export type PlanningContextProviderProps = {
    weekYear: string,
    weekNumber: string,
    children: JSX.Element
}

export default function PlanningContextProvider({ weekYear, weekNumber, children }: PlanningContextProviderProps) {

    const [state, dispatch,error] = useAsyncReducer(PlanningReducer, initialState(weekYear, weekNumber));
    const actions: PlanningActions = createActions(dispatch);

    useEffect(() => {
        actions.fetchCurrentState()
    },[])

    return (
        <>
            <PlanningContext.Provider value={{state, actions, error}}>
                {children}
            </PlanningContext.Provider>
        </>
    );
}

