
import Week, {initWeek} from "../model/week.interface";
import AssignedSchedule from "../model/assignedSchedule.interface";
import IPlanningChange from "../model/planningChange.interface";
import DemandWeek from "../model/demandWeek.interface";
import { GenericState } from "../../Generic/context/genericState.interface";

export interface PlanningState extends GenericState {
    initialized: boolean,
    isLogged: boolean,
    week: Week | null,
    assignedSchedules: AssignedSchedule[],
    changes: IPlanningChange[],
    dayUpdated: boolean,
    incompleteSolution: boolean,
    demandWeek: DemandWeek | null,
    print: boolean
}

export const initialState = (weekYear: string = '', weekNumber: string = ''): PlanningState => {
    return {
        initialized: false,
        isLogged: false,
        week: initWeek(weekYear, weekNumber),
        assignedSchedules: [],
        changes: [],
        dayUpdated: false,
        incompleteSolution: false,
        demandWeek: null,
        errors: null,
        print: false
    }
}

// day representation = YYMMDD
export const dayToDayIndex = (day: Date): string => {
    return day.getFullYear().toString().slice(2) + day.getMonth().toString() + day.getDay().toString();
}