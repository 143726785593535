import {DataTransformer, InputDataTransformer} from "../../../Generic/dataTransformer/dataTransformer.interface";
import Replacement from "../replacement.interface";
import Assignment, { isAssigment } from "../assignment.interface";
import {assignmentDataTransformer} from "./assignmentDataTransformer";
import Technician from "../technician.interface";
import {isString} from "../../../Generic/libraries/string";

export interface InputReplacement extends InputDataTransformer {
    id: number,
    assignmentReplaced: Assignment | number | null,
    assignmentReplacing: Assignment | number | null,
    comment: string,
    replaced: Technician,
    replacing: Technician
}

export const replacementDataTransformer: DataTransformer<Replacement> = {

    transform: (inputReplacement: InputReplacement): Replacement => {
        const assignmentReplaced = getReplacedIngAssignment(inputReplacement.assignmentReplaced);
        const assignmentReplacing = getReplacedIngAssignment(inputReplacement.assignmentReplacing);

        return {
            id: inputReplacement.id,
            replacing: inputReplacement.replacing,
            replaced: inputReplacement.replaced,
            frontId: '',
            assignmentReplaced,
            assignmentReplacing,
            comment: inputReplacement.comment
        };
    }
}

const getReplacedIngAssignment = (elem: Assignment | number | null): string | number | null => {
    if (isAssigment(elem)) {
        return elem.id
    }
    return elem
}