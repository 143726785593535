import Technician from "../technician.interface";
import Line from "../line.interface";
import Qualification, {QualificationLevel} from "../qualification.interface";
import {PlanningContainer} from "../../context/container";
import {DateUtils} from "../../../Generic/libraries/dateManipulation";
import {isNumber} from "lodash";

export const TechnicianService = {
    isTechnicianTrainedForLine: (technician: Technician|number, line: Line|number): boolean => {
        if (isNumber(technician)) {
            technician = PlanningContainer.technicianRepository.findOne(technician)
        }
        if (isNumber(line)) {
            line = PlanningContainer.lineRepository.findOne(line)
        }

        return _filterTechnicianQualificationsForLine(technician, line, QualificationLevel.trained).length !== 0
            || _filterTechnicianQualificationsForLine(technician, line, QualificationLevel.qualified).length !== 0 //qualified technician is also trained
            || _filterTechnicianQualificationsForLine(technician, line, QualificationLevel.in_qualification).length !== 0 //in_qualification technician is also trained
        ;
    },

    isTechnicianTrainedOrInTrainingForLine: (technician: Technician|number, line: Line|number): boolean => {
        if (isNumber(technician)) {
            technician = PlanningContainer.technicianRepository.findOne(technician)
        }
        if (isNumber(line)) {
            line = PlanningContainer.lineRepository.findOne(line)
        }

        return _filterTechnicianQualificationsForLine(technician, line, QualificationLevel.trained).length !== 0
            || _filterTechnicianQualificationsForLine(technician, line, QualificationLevel.in_training).length !== 0 //qualified technician is also in training 
            || _filterTechnicianQualificationsForLine(technician, line, QualificationLevel.qualified).length !== 0 //qualified technician is also trained
            || _filterTechnicianQualificationsForLine(technician, line, QualificationLevel.in_qualification).length !== 0 //in_qualification technician is also trained
        ;
    },

    isTechnicianInTrainingForLine: (technician: Technician|number, line: Line|number): boolean => {
        if (isNumber(technician)) {
            technician = PlanningContainer.technicianRepository.findOne(technician)
        }
        if (isNumber(line)) {
            line = PlanningContainer.lineRepository.findOne(line)
        }

        return _filterTechnicianQualificationsForLine(technician, line, QualificationLevel.in_training).length !== 0;
    },

    isTechnicianInQualificationForLine: (technician: Technician|number, line: Line|number): boolean => {
        if (isNumber(technician)) {
            technician = PlanningContainer.technicianRepository.findOne(technician)
        }
        if (isNumber(line)) {
            line = PlanningContainer.lineRepository.findOne(line)
        }

        return _filterTechnicianQualificationsForLine(technician, line, QualificationLevel.in_qualification).length !== 0;
    },

    isTechnicianQualifiedForLine: (technician: Technician|number, line: Line|number): boolean => {
        if (isNumber(technician)) {
            technician = PlanningContainer.technicianRepository.findOne(technician)
        }
        if (isNumber(line)) {
            line = PlanningContainer.lineRepository.findOne(line)
        }
        return _filterTechnicianQualificationsForLine(technician, line, QualificationLevel.qualified).length !== 0;
    },

    hasTechnicianADayOff: (technician: Technician, d: Date): boolean => {
        return technician.inactiveDays.filter((day: Date) => {
            return DateUtils.dateEquals(d, day)
        }).length > 0;
    }
}

const _filterTechnicianQualificationsForLine = (technician: Technician, line: Line, qualificationLevel: string): Array<Qualification> => {
  return technician.qualifications.filter((qualification: Qualification) => {
        return qualification.competency.linesId.filter((lineId: number) => {
            return PlanningContainer.lineRepository.findOne(lineId).id === line.id && qualification.level === qualificationLevel
        }).length !==0
    })
}
