import AssignedSchedule from "../assignedSchedule.interface";
import {
    AssignedSchedulePlanningChange,
    AssignmentPlanningChange,
    ChangeObjectType,
    ChangeType,
    ReplacementPlanningChange
} from "../planningChange.interface";
import Assignment from "../assignment.interface";
import Replacement from "../replacement.interface";

export function createAssignedSchedulePlanningChange(type: ChangeType, changes: AssignedSchedule, originalData: AssignedSchedule): AssignedSchedulePlanningChange {

    return {
        type,
        objectType: ChangeObjectType.Assigned_Schedule,
        id: changes.id,
        frontId: changes.frontId,
        details: changes,
        originalDetails: originalData
    }
}


export function createAssignmentPlanningChange(type: ChangeType, changes: Assignment, originalData: Assignment): AssignmentPlanningChange {

    return {
        type,
        objectType: ChangeObjectType.Assignment,
        id: changes.id,
        frontId: changes.frontId,
        details: changes,
        originalDetails: originalData,
        assignedScheduleId: changes.assignedScheduleId,
        assignedScheduleFrontId: changes.assignedScheduleFrontId
    }
}

export function createReplacementPlanningChange(type: ChangeType, changes: Replacement, originalData: Replacement): ReplacementPlanningChange {

    return {
        type,
        objectType: ChangeObjectType.Replacement,
        id: changes.id,
        frontId: changes.frontId,
        details: changes,
        originalDetails: originalData,
    }
}