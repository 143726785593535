import {DataTransformer, InputDataTransformer} from "../../../Generic/dataTransformer/dataTransformer.interface";
import Variable from "../variable.interface";
import Technician from "../technician.interface";
import AssignedSchedule from "../assignedSchedule.interface";
import {technicianDataTransformer} from "./technicianDataTransformer";
import {assignedScheduleDataTransformer} from "./assignedSchedulesDataTransformer";

export interface InputVariable extends InputDataTransformer {
    technician: Technician,
    assignedSchedule: AssignedSchedule
}

export const variableDataTransformer: DataTransformer<Variable> = {

    transform: (inputVariable: InputVariable): Variable => {
        return {
            technician: technicianDataTransformer.transform(inputVariable.technician),
            assignedSchedule : assignedScheduleDataTransformer.transform(inputVariable.assignedSchedule)
        };
    }
}