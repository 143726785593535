import {Constraint} from "../../../Generic/validation/constraint.interface";
import Schedule from "../../model/schedule.interface";
import Technician from "../../model/technician.interface";
import {SamePlanningModeForTechnicianAndScheduleConstraint} from "./SamePlanningModeForTechnicianAndScheduleConstraint";

export class NotSamePlanningModeForTechnicianAndScheduleConstraint implements Constraint {
    _samePlanningModeConstraint: SamePlanningModeForTechnicianAndScheduleConstraint
    errorMessage: "Technician has not the same planning mode as the Schedule"

    constructor(schedule: number|Schedule|null, technician: number|Technician) {
        this._samePlanningModeConstraint = new SamePlanningModeForTechnicianAndScheduleConstraint(schedule, technician)
    }

    /**
     * Is valid when
     * 1. Schedule is null
     * 2. Schedule has not an 'alternating' planning mode
     * 3. Technician has not an 'alternating' planning mode
     * 4. both have the same 'alternating' planning mode
     */
    isValid(): boolean {
        return !this._samePlanningModeConstraint.isValid()
    }
}

