import {Constraint} from "./constraint.interface";
import {Validator} from "./validator.interface";
import {ValidationError} from "./validationError.interface";

export class GenericValidator implements Validator {
    validationErrors: ValidationError[] = []

    //stops at the first error and does not compute the remaining ones
    validate(constraints: Constraint[]): boolean {
        this.validationErrors = []

        return constraints.reduce((validity: boolean, constraint: Constraint) => {
            if (!validity) {
                return validity
            }
            return this._checkValidity(constraint)
        }, true)
    }

    private _checkValidity(constraint: Constraint): boolean {
        const valid: boolean = constraint.isValid()

        if (!valid) {
            this.validationErrors.push({
                type: constraint.constructor.name,
                message: constraint.errorMessage
            })
        }

        return valid
    }
}