import {Constraint} from "../../../Generic/validation/constraint.interface";
import Schedule from "../../model/schedule.interface";
import Technician, {AlternatingPlanningModes} from "../../model/technician.interface";
import {isNumber} from "lodash";
import {PlanningContainer} from "../../context/container";
import {inArray} from "../../../Generic/libraries/array";

export class SamePlanningModeForTechnicianAndScheduleConstraint implements Constraint {
    _schedule: Schedule|null
    _technician: Technician
    errorMessage: "Technician has not the same planning mode as the Schedule"

    constructor(schedule: number|Schedule|null, technician: number|Technician) {
        if (isNumber(schedule)) {
            schedule = PlanningContainer.scheduleRepository.findOne(schedule)
        }
        this._schedule = schedule

        if (isNumber(technician)) {
            technician = PlanningContainer.technicianRepository.findOne(technician)
        }
        this._technician = technician
    }

    /**
     * Is valid when
     * 1. Schedule is null
     * 3. Technician has not an 'alternating' planning mode
     * 4. both have the same 'alternating' planning mode
     */
    isValid(): boolean {
        return this._schedule === null
        || !inArray(AlternatingPlanningModes, this._technician.planningMode)
        || (
            inArray(AlternatingPlanningModes, this._schedule.planningMode)
            && this._schedule.planningMode === this._technician.planningMode
        )
    }
}

