import Competency from "../model/competency.interface";
import IRepository from "./repository.interface";
import PlanningLocalStorage from "./Storage/planningLocalStorage";

export default class CompetencyRepository implements IRepository<Competency>{
    localRepo: PlanningLocalStorage

    constructor(localRepo: PlanningLocalStorage) {
        this.localRepo = localRepo;
    }

    public findAll(): Competency[] {
        return this.localRepo.competencies;
    }

    public findOne(competencyId: number): Competency|null {
        const competency = this.localRepo.competencies.filter((c: Competency) => {
            return c.id === competencyId
        })

        if (competency.length === 1) {
            return competency[0];
        } else {
            return null
        }
    }
}