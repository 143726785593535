import {PlanningState} from "../../context/state";
import AssignedSchedule, {isSameAssignedSchedule} from "../../model/assignedSchedule.interface";
import {DateUtils} from "../../../Generic/libraries/dateManipulation";
import Line from "../../model/line.interface";
import Schedule from "../../model/schedule.interface";
import {PlanningContainer} from "../../context/container";
import {sortBy} from "../../../Generic/libraries/sort";
import Technician from "../../model/technician.interface";
import {assignedScheduleRepository} from "../assignedScheduleRepository";
import {TechnicianService} from "../../model/Service/technicianService";
import Assignment from "../../model/assignment.interface";
import {
    SamePlanningModeForTechnicianAndScheduleConstraint
} from "../../validation/constraints/SamePlanningModeForTechnicianAndScheduleConstraint";
import {BooleanConstraint} from "../../../Generic/validation/constraint/BooleanConstraint";
import {SameConstraint} from "../../../Generic/validation/constraint/sameConstraint";
import {NotSameConstraint} from "../../../Generic/validation/constraint/notSameConstraint";


export const getAssignedSchedule = (state: PlanningState, id : number | null, frontId: string | null): AssignedSchedule => {
    return state.assignedSchedules.filter((a: AssignedSchedule) => {
        return (id !== null && a.id === id) || (frontId !== null && a.frontId === frontId)
    })[0]
}

/**
 * Adds all the assigned Schedules to the state assignedSchedules 
 * Returns the newly updated assignedSchedules list
 */
export const addAssignedSchedules = (state: PlanningState, assignedSchedules: AssignedSchedule[]): AssignedSchedule[] => {
    return [...state.assignedSchedules, ...assignedSchedules]
}

export const updateAssignedSchedules = (state: PlanningState, assignedSchedules: AssignedSchedule[]): AssignedSchedule[] => {
    let updatedAssignedSchedules = [...state.assignedSchedules]

    assignedSchedules.forEach((updatedAssignedSchedule: AssignedSchedule) => {
        updatedAssignedSchedules = updatedAssignedSchedules.map((a: AssignedSchedule) => {
            if (a.id === updatedAssignedSchedule.id && a.frontId === updatedAssignedSchedule.frontId) {
                return updatedAssignedSchedule
            } else {
                return a
            }
        })
    })

    return updatedAssignedSchedules
}

/**
 * Removes the assigned Schedules from the state assignedSchedules 
 * Returns the newly updated state assignedSchedules list
 */
export const removeAssignedSchedules = (state: PlanningState, assignedSchedules: AssignedSchedule[]): AssignedSchedule[] => {
    return state.assignedSchedules.filter((existingA: AssignedSchedule) => {
        return assignedSchedules.filter((toRemoveA: AssignedSchedule) => {
            return isSameAssignedSchedule(toRemoveA, existingA)
        }).length === 0 //true if existingAssignedSchedule is not in toRemoveAssignedSchedules
    })
}

export const getAssignedScheduleForAssignment = (state: PlanningState, assignment : Assignment): AssignedSchedule | null => {
    const aSchedules = state.assignedSchedules.filter((a: AssignedSchedule) => {
        return a.id === assignment.assignedScheduleId && a.frontId === assignment.assignedScheduleFrontId;
    })

    if (aSchedules.length === 0) {
        return null
    }
    return aSchedules[0];
}

export const findAllAssignedSchedulesForDay = (state: PlanningState, d: Date): AssignedSchedule[] => {
    return state.assignedSchedules.filter((assignedSchedule: AssignedSchedule) => {
        return DateUtils.dateEquals(assignedSchedule.day, d);
    })
}

export const findAllAssignedSchedulesForDayAndLine = (state: PlanningState, d: Date, l: Line): AssignedSchedule[] => {
    return state.assignedSchedules.filter((assignedSchedule: AssignedSchedule) => {
        return DateUtils.dateEquals(assignedSchedule.day, d) && assignedSchedule.lineId === l.id;
    })
}

export const getSchedulesForAssignedSchedule = (state: PlanningState, assignedSchedule: AssignedSchedule | null ): Schedule[] => {
    const scheduleRepository = PlanningContainer.scheduleRepository

    const alreadyExistingSchedulesForDayAndLine = state.assignedSchedules.reduce((schedules: Schedule[], a: AssignedSchedule) => {
        const ofInterest = PlanningContainer.validator.validate([
            new SameConstraint(a.day, assignedSchedule.day, DateUtils.dateEquals),
            new SameConstraint(a, assignedSchedule, 'lineId'),
            new NotSameConstraint(a, assignedSchedule, isSameAssignedSchedule)
        ])
        if (ofInterest) {
            return [...schedules, scheduleRepository.findOne(a.scheduleId)]
        }
        return schedules
    }, [])

    let availableSchedules = scheduleRepository.findAll().filter(s => {
        //true if s is not in alreadyExistingSchedulesForDayAndLine / false otherwise
        return alreadyExistingSchedulesForDayAndLine.filter((aSchedule: Schedule) => aSchedule.id === s.id).length === 0
    })

    if (assignedSchedule?.scheduleId) {
        availableSchedules = [...new Set([...availableSchedules, scheduleRepository.findOne(assignedSchedule.scheduleId)])]
    } else {
        availableSchedules = [...new Set([...availableSchedules])]
    }

    return sortBy(availableSchedules, 'name')
}

export const getAlreadyAssignedTechniciansNotInAssignedSchedule = (state: PlanningState, assignedSchedule: AssignedSchedule): Technician[] => {
    return state.assignedSchedules.reduce((technicians: Technician[], a: AssignedSchedule): Technician[] => {
        if (DateUtils.dateEquals(assignedSchedule.day, a.day) && !isSameAssignedSchedule(a, assignedSchedule)) {
            return [...technicians, ...assignedScheduleRepository.getAssignedTechnicians(a)]
        } else {
            return [...technicians]
        }
    }, [])
}
