
import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {PlanningContext} from "../../context/PlanningContextProvider";
import Loader from 'react-loader-spinner';
import useTranslation from "../../../Generic/hooks/useTranslation";

export default function FullHeader() {
    const {state, actions} = useContext(PlanningContext)
    const {trans} = useTranslation();
    const [navigating, setNavigating] = useState({previous: false, next: false})

    useEffect(() => {
        setNavigating({previous: false, next: false})
    },[state])

    const navigate = (direction: 'previous' | 'next'): void => {
        if (direction === 'previous') {
            setNavigating({previous: true, next: false})
            actions.goToPreviousWeek()
        } else {
            setNavigating({previous: false, next: true})
            actions.goToNextWeek()
        }
    }

    const weekNumberDisplay = state.week.year+' - W'+(state.week.number === 53?'53/W1':state.week.number);

    return (
        <div className="columns">
            <div className="navigationBackward column is-1">
                {!state.print ?
                    ! navigating.previous ?(
                        <div onClick={() => navigate('previous')}>
                            <span className="icon">
                                <FontAwesomeIcon icon={faAngleLeft} />
                            </span>
                        </div>
                    ):(
                        <Loader
                            type="TailSpin"
                            color="#00BFFF"
                            height={20}
                            width={20}
                            visible={true}
                        />
                    ) : ''
                }
            </div>
            <div className="headerTitle column is-10">
                <span>{trans('planning.table.mainTitle')}{' '+ weekNumberDisplay}</span> {state.incompleteSolution && state.isLogged ?(<span className='incompleteSolutionTableHeader'> - {trans('planning.table.mainTitle.incompleteSolution')}</span>):''}
            </div>
            <div className="navigationForward column is-1">
                {!state.print ?
                    ! navigating.next?(
                        <div onClick={() => navigate('next')}>
                            <span className="icon">
                              <FontAwesomeIcon icon={faAngleRight} />
                            </span>
                        </div>
                    ):(
                        <Loader
                            type="TailSpin"
                            color="#00BFFF"
                            height={20}
                            width={20}
                            visible={true}
                        />
                    ) :''}
            </div>
        </div>
    );
}