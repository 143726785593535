import Week from "../model/week.interface";
import PlanningLocalStorage from "./Storage/planningLocalStorage";
import {lineDataTransformer} from "../model/DataTransformer/lineDataTransformer";
import {bulkTransform} from "../../Generic/dataTransformer/bulkDataTransformer";
import {technicianDataTransformer} from "../model/DataTransformer/technicianDataTransformer";
import {scheduleDataTransformer} from "../model/DataTransformer/scheduleDataTransformer";
import {competencyDataTransformer} from "../model/DataTransformer/competencyDataTransformer";
import {InputState} from "../model/DataTransformer/stateDataTransformer";
import IPlanningChange from "../model/planningChange.interface";
import {downloadPDFFromBlob} from "../../Generic/libraries/download";
import Line from "../model/line.interface";
import Competency from "../model/competency.interface";
import Technician from "../model/technician.interface";
import Schedule from "../model/schedule.interface";

const Routing = require('../../Generic/libraries/routing');

export enum RefreshDirection {
    Previous= 'previous',
    Current= 'current',
    Next= 'next'
}

export default class BackendRepository {
    localStorage: PlanningLocalStorage

    constructor(planningLocalStorage: PlanningLocalStorage) {
        this.localStorage = planningLocalStorage;
    }

    public refreshData(week: Week, direction: RefreshDirection = RefreshDirection.Current): Promise<InputState> {
        return new Promise((resolve, reject) => {
            const planningDataRoute = Routing.generate('planning_get_week_data', {
                'weekYear': week.year.toString(),
                'weekNumber': week.number.toString(),
                'direction': direction
            })

            let inputState: InputState

            Promise.all(this.getDataPromise(planningDataRoute, week, direction))
                .then((values) => {
                    console.log("Fetched values", values)
                    const inputStateData: InputState = values[0] as InputState
                    const lines: Line[]|null = values[1] as Line[]
                    const competencies: Competency[]|null = values[2] as Competency[]
                    const schedules: Schedule[]|null = values[3] as Schedule[]

                    this.localStorage.lines = lines ? bulkTransform(lineDataTransformer, lines) : [];
                    this.localStorage.competencies = competencies ? bulkTransform(competencyDataTransformer, competencies) : [];
                    this.localStorage.schedules = schedules ? bulkTransform(scheduleDataTransformer, schedules) : [];

                    inputState = inputStateData
                    return this.getParsedDataPromise<Technician[]>(Routing.generate('planning_get_technicians', {
                        weekYear: week.year,
                        weekNumber: week.number,
                        direction
                    }))
                })
                .then(technicians => {
                    console.log("Fetched Technicians", technicians)
                    this.localStorage.technicians = technicians ? bulkTransform(technicianDataTransformer, technicians) : [];
                    resolve(inputState)
                })
                .catch(reject)
        })
    }

    public refreshInterimData(week: Week, direction: RefreshDirection = RefreshDirection.Current): Promise<InputState> {
        return new Promise((resolve, reject) => {
            const route = Routing.generate('planning_get_interim_planning_data', {
                'direction': direction,
                'weekYear': week.year.toString(),
                'weekNumber': week.number.toString()
            });

            let inputState: InputState
            Promise.all(this.getDataPromise(route, week, direction))
                .then((values) => {
                    console.log("Fetched interim values", values)
                    const inputStateData: InputState = values[0] as InputState
                    const lines: Line[]|null = values[1] as Line[]
                    const competencies: Competency[]|null = values[2] as Competency[]
                    const schedules: Schedule[]|null = values[3] as Schedule[]

                    this.localStorage.lines = lines ? bulkTransform(lineDataTransformer, lines) : [];
                    this.localStorage.competencies = competencies ? bulkTransform(competencyDataTransformer, competencies) : [];
                    this.localStorage.schedules = schedules ? bulkTransform(scheduleDataTransformer, schedules) : [];

                    inputState = inputStateData
                    return this.getParsedDataPromise<Technician[]>(Routing.generate('planning_get_technicians', {
                        weekYear: week.year,
                        weekNumber: week.number,
                        direction
                    }))
                })
                .then(technicians => {
                    console.log("Fetched Technicians", technicians)
                    this.localStorage.technicians = technicians ? bulkTransform(technicianDataTransformer, technicians) : [];
                    resolve(inputState)
                })
                .catch(reject)
                .catch(reject)
        })
    }

    public saveChanges(week: Week, changes: IPlanningChange[]): Promise<InputState> {
        return new Promise((resolve, reject) => {
            const route = Routing.generate('planning_save_week_data', {'weekYear': week.year, 'weekNumber': week.number})
            const saveDataHeaders = new Headers({
                'Content-Type': 'application/json'
            });

            //allows the date to keep the correct hours and not change due to UTC
            Date.prototype.toJSON = function(){
                this.setHours(12); // Hack to ensure it's the middle of the day and so timezones doesn't impact the date generated in backend
                return this.toDateString();
            };

            fetch(route, {
                method: 'POST',
                headers: saveDataHeaders,
                body: JSON.stringify(changes)
            })
                .then((response: Response) => {
                    if (!response.ok) {
                        throw new Error("Issue in the state fetch.")
                    }
                    return response.json()
                })
                .then(data => {
                    data = JSON.parse(data)
                    console.log('Updated Data', data)
                    resolve(data)
                })
                .catch(reject)
        })
    }

    public getInterimOperatorPlanningPDF(week: Week, direction: RefreshDirection = RefreshDirection.Current): Promise<InputState> {
        return new Promise((_, reject) => {
            const route = Routing.generate('pdf_export_planning_interim_operator', {
                'direction': direction,
                'weekYear': week.year.toString(),
                'weekNumber': week.number.toString()
            })
            fetch(route)
                .then((response: Response) => {
                    if (!response.ok) {
                        throw new Error("Issue in the state fetch.")
                    }
                    return response.blob() //returns PDF as Binary
                })
                .then(blob => {
                    downloadPDFFromBlob(blob, 'export_interim_operator_planning.pdf')
                })
                .catch(err => reject(err))
            ;
        })
    }

    private getDataPromise(planningDataRoute: string, week: Week, direction: string): Promise<any>[] {

        return [
            this.getParsedDataPromise<InputState>(planningDataRoute),
            this.getParsedDataPromise<Line[]>( Routing.generate('planning_get_lines')),
            this.getParsedDataPromise<Competency[]>(Routing.generate('planning_get_competencies')),
            this.getParsedDataPromise<Schedule[]>(Routing.generate('planning_get_schedules')),
        ]
    }

    private async getParsedDataPromise<T>(route: string): Promise<T> {
        const text = await this.fetchData(route);
        return JSON.parse(text);
    }

    private async fetchData(route: string): Promise<string> {
        return new Promise((resolve, reject) => {
            fetch(route)
                .then((response: Response) => {
                    if (!response.ok) {
                        throw new Error("Issue in the state fetch.")
                    }
                    return response.json()
                })
                .then(resolve)
                .catch(reject)
            ;
        })

    }
}

