import Assignment from "../assignment.interface";
import {create_UUID} from "../../../Generic/libraries/uuid";
import AssignedSchedule from "../assignedSchedule.interface";

export const AssignmentFactory = {
    create: (day: Date, technicianId: number, assignedSchedule: AssignedSchedule): Assignment => {

        return {
            id: null,
            frontId: create_UUID(),
            technicianId,
            assignedScheduleId: assignedSchedule.id,
            assignedScheduleFrontId: assignedSchedule.frontId,
            day,
            replaced: false,
            replacing: false,
            replacedReplacement: null,
            replacingReplacement: null,
            updatedAt: new Date(),
            changed: false
        }
    }
}