import {Constraint} from "../constraint.interface";
import {ComparatorFunction, createTrueComparatorFunction} from "../comparatorFunctionFactory";


export class SameConstraint<T> implements Constraint {
    _elementA : T
    _elementB: T
    _comparator: ComparatorFunction<T>
    errorMessage: string;

    constructor(elementA: T, elementB: T, comparator: string|ComparatorFunction<T>|null =  null, errorMessage: string = '') {
        this._elementA = elementA
        this._elementB = elementB
        this.errorMessage = errorMessage
        this._comparator = createTrueComparatorFunction(comparator)
    }

    isValid(): boolean {
        return this._comparator(this._elementA, this._elementB)
    }

}