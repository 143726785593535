import {PlanningState} from "../state";
import Assignment from "../../model/assignment.interface";
import {UpdateState} from "./updateState.interface";
import AssignedSchedule from "../../model/assignedSchedule.interface";
import {createAssignmentPlanningChange} from "../../model/Factory/PlanningChangeFactory";
import {ChangeType} from "../../model/planningChange.interface";
import Technician from "../../model/technician.interface";
import {update} from "lodash";
import {addChanges} from "../../repository/State/stateChangesRepository";
import {updateAssignmentInAssignedSchedule} from "./actionAssignedSchedulesHandling";
import {updateAssignedSchedules} from "../../repository/State/stateAssignedSchedulesRepository";

export default function changeAssignmentTechnician(state: PlanningState, assignment: Assignment, newTechnician: Technician): UpdateState | null {
    /**
     * 1. update assignment with new Technician
     * 2. create AssignmentChange
     * 3. Update AssignedSchedules and replace it in state assignedSchedules
     */

    const updatedAssignment: Assignment = {
        ...assignment,
        technicianId: newTechnician.id
    }

    const assignmentChange = createAssignmentPlanningChange(ChangeType.Creation, updatedAssignment, assignment)

    const updatedAssignedSchedule: AssignedSchedule = updateAssignmentInAssignedSchedule(state, updatedAssignment)

    return {
        changes: addChanges(state, [assignmentChange]),
        assignedSchedules: updateAssignedSchedules(state, [updatedAssignedSchedule])
    }
}
