import {DataTransformer, InputDataTransformer} from "../../../Generic/dataTransformer/dataTransformer.interface";
import Line from "../line.interface";

export interface InputLine extends InputDataTransformer {
    id: number,
    name: string,
    displayName: string,
    color: string,
    number: number,
    capacity: number,
    topCapacity: number
    priority: number,
}

export const lineDataTransformer: DataTransformer<Line> = {

    transform: (inputLine: InputLine): Line => {
        return {
            id: inputLine.id,
            name: inputLine.name,
            displayName: inputLine.displayName,
            color: inputLine.color,
            number: inputLine.number,
            capacity: inputLine.capacity,
            topCapacity: inputLine.topCapacity,
            priority: inputLine.priority
        };
    }
}