import {DataTransformer, InputDataTransformer} from "../../../Generic/dataTransformer/dataTransformer.interface";
import Solution from "../solution.interface";
import Variable from "../variable.interface";
import {bulkTransform} from "../../../Generic/dataTransformer/bulkDataTransformer";
import {variableDataTransformer} from "./variableDataTransformer";

export interface InputSolution extends InputDataTransformer {
    complete: boolean,
    variables: Variable[]
}

export const solutionDataTransformer: DataTransformer<Solution> = {

    transform: (inputSolution: InputSolution): Solution => {
        return {
            complete: inputSolution.complete,
            variables: bulkTransform<Variable>(variableDataTransformer, inputSolution.variables)
        };
    }
}