import {PlanningState} from "../state";
import {UpdateState} from "./updateState.interface";
import Assignment, { isSameAssigment } from "../../model/assignment.interface";
import Replacement, { isReplacement } from "../../model/replacement.interface";
import IPlanningChange, { ChangeType } from "../../model/planningChange.interface";
import { findOneAssignment } from "../../repository/State/stateAssignmentRepository";
import { addChanges } from "../../repository/State/stateChangesRepository";
import {  updateAssignedSchedules } from "../../repository/State/stateAssignedSchedulesRepository";
import { getUpdatedAssignedSchedulesFromAssignments } from "./actionAssignedSchedulesHandling";
import {createReplacementPlanningChange} from "../../model/Factory/PlanningChangeFactory";

export default function deleteReplacement(state: PlanningState, fromAssignment: Assignment): UpdateState | null {
    const replacement: Replacement = isReplacement(fromAssignment.replacedReplacement) ? fromAssignment.replacedReplacement : null
    if (replacement === null) {
        return {
            changes: state.changes,
            assignedSchedules: state.assignedSchedules
        }
    }
    
    let changes: IPlanningChange[] = [createReplacementPlanningChange(ChangeType.Deletion, replacement, replacement)]

    //update replacedAssignment
    const replacedAssignment = findOneAssignment(state, replacement.assignmentReplaced)
    const updatedReplacedAssignment: Assignment = {
        ...replacedAssignment,
        replaced: false,
        replacedReplacement: null
    }
    
    //update replacingAssignment
    const replacingAssignment = findOneAssignment(state, replacement.assignmentReplacing)
    const updatedReplacingAssignment: Assignment = {
        ...(isSameAssigment(replacedAssignment, replacingAssignment) ? updatedReplacedAssignment : replacingAssignment),
        replacing: false,
        replacedReplacement: null
    }

    return {
        changes: addChanges(state, changes),
        assignedSchedules: updateAssignedSchedules(state, getUpdatedAssignedSchedulesFromAssignments(state, [updatedReplacedAssignment, updatedReplacingAssignment]))
    }
}