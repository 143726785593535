import Assignment, {isSameAssigment} from "../../model/assignment.interface";
import AssignedSchedule, {isSameAssignedSchedule} from "../../model/assignedSchedule.interface";
import {PlanningState} from "../../context/state";
import {
    findAllAssignedSchedulesForDay,
    getAssignedScheduleForAssignment
} from "./stateAssignedSchedulesRepository";
import {PlanningContainer} from "../../context/container";
import {sortByFunction} from "../../../Generic/libraries/sort";
import { isString } from "../../../Generic/libraries/string";
import {
    TechnicianIsTrainedForAssignedScheduleConstraint
} from "../../validation/constraints/TechnicianIsTrainedForAssignedSchedule.constraint";
import Technician from "../../model/technician.interface";
import {
    AssignmentNotAlreadyReplacingConstraint
} from "../../validation/constraints/AssignmentNotAlreadyReplacing.constraint";
import {AssignmentNotReplacedConstraint} from "../../validation/constraints/AssignmentNotReplaced.constraint";
import Planning from "../../planning";
import {NotSameConstraint} from "../../../Generic/validation/constraint/notSameConstraint";

export const findAllAssignments = (state: PlanningState): Assignment[] => {
    return state.assignedSchedules.reduce((allAssignments: Assignment[], assignedSchedule: AssignedSchedule) => {
        return allAssignments.concat([...assignedSchedule.assignments])
    }, [])
}

export const findOneAssignment = (state: PlanningState, id: number | string): Assignment | null => {
    const assignment: Assignment[] = findAllAssignments(state).filter((a: Assignment) => {
        return (isString(id) && id === a.frontId) || id == a.id
    })

    if (assignment.length === 0) {
        return null
    }

    return assignment[0]
}

export const findAllAssignmentsForDay = (state: PlanningState, d: Date): Assignment[] => {
    return findAllAssignedSchedulesForDay(state, d).reduce((allAssignments: Assignment[], assignedSchedule: AssignedSchedule) : Assignment[] => {
        return [...allAssignments, ...assignedSchedule.assignments]
    }, [])
}

export const getAvailableAssignmentsForReplacement = (state: PlanningState,  assignment: Assignment): Assignment[] =>{
    const assignedSchedule = getAssignedScheduleForAssignment(state, assignment)

    const assignmentsForDay = findAllAssignmentsForDay(state, assignedSchedule.day).filter((tempAssignment: Assignment) => {
        let tempAssignedSchedule = getAssignedScheduleForAssignment(state, tempAssignment);
        
        return canAssignmentBeReplacedBy(
            assignment,
            assignedSchedule,
            tempAssignment,
            tempAssignedSchedule
        )
    });

    return sortByFunction(assignmentsForDay, (a: Assignment) => PlanningContainer.technicianRepository.findOne(a.technicianId).trigram)
}

export const canAssignmentBeReplacedBy = (replacedAssignment: Assignment, replacedAssignedSchedule: AssignedSchedule, replacingAssignment: Assignment, replacingAssignedSchedule: AssignedSchedule): boolean => {
    const replacingTechnician: Technician = PlanningContainer.technicianRepository.findOne(replacingAssignment.technicianId)

    return PlanningContainer.validator.validate([
        new NotSameConstraint(replacedAssignment, replacingAssignment, isSameAssigment),
        new NotSameConstraint(replacingAssignment, replacingAssignment, 'technicianId'),
        new NotSameConstraint(replacedAssignedSchedule, replacingAssignedSchedule, isSameAssignedSchedule),
        new TechnicianIsTrainedForAssignedScheduleConstraint(replacingTechnician, replacedAssignedSchedule),
        new AssignmentNotAlreadyReplacingConstraint(replacedAssignment, replacingAssignment),
        new AssignmentNotReplacedConstraint(replacingAssignment)
    ])
}
