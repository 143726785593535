import {PlanningState} from "../state";
import {UpdateState} from "./updateState.interface";
import AssignedSchedule from "../../model/assignedSchedule.interface";
import Technician from "../../model/technician.interface";
import {AssignedScheduleFactory} from "../../model/Factory/assignedScheduleFactory";
import {PlanningContainer} from "../container";
import {
    ScheduleNotExistingForTheDayAndTheLineConstraint
} from "../../validation/constraints/ScheduleNotExistingForTheDayAndTheLineConstraint";
import {
    AssignedScheduleTechniciansNotAssignedForTheDayConstraint
} from "../../validation/constraints/AssignedScheduleTechniciansNotAssignedForTheDay.constraint";
import {
    AssignedScheduleTechniciansAvailableOnTheDayConstraint
} from "../../validation/constraints/AssignedScheduleTechniciansAvailableOnTheDay.constraint";
import {addChanges} from "../../repository/State/stateChangesRepository";
import IPlanningChange, {ChangeType} from "../../model/planningChange.interface";
import {
    createAssignedSchedulePlanningChange,
    createAssignmentPlanningChange
} from "../../model/Factory/PlanningChangeFactory";
import {addAssignedSchedules} from "../../repository/State/stateAssignedSchedulesRepository";
import Assignment from "../../model/assignment.interface";
import {
    TechnicianAvailableOnTheDayConstraint
} from "../../validation/constraints/TechnicianAvailableOnTheDay.constraint";
import {
    TechnicianNotAssignedOnTheDayConstraint
} from "../../validation/constraints/TechnicianNotAssignedOnTheDay.constraint";

export interface DuplicateAssignedScheduleData {
    days: Date[],
    technicians: Technician[]
}

export default function duplicateAssignedSchedule(
    state: PlanningState,
    assignedSchedule : AssignedSchedule,
    duplicateData: DuplicateAssignedScheduleData
): UpdateState | null {
    let newAssignedSchedules = []
    let changes = []

    let errors = []
    duplicateData.days.forEach((d: Date) => {
        if (!PlanningContainer.validator.validate([
            new ScheduleNotExistingForTheDayAndTheLineConstraint(state, assignedSchedule.scheduleId, assignedSchedule.lineId, d)
        ])) {
            errors = [...errors, ...PlanningContainer.validator.validationErrors]
            return
        }

        const validTechniciansToDuplicate = duplicateData.technicians.filter((technician: Technician) => {
             if (!PlanningContainer.validator.validate([
                new TechnicianNotAssignedOnTheDayConstraint(state, technician, d),
                new TechnicianAvailableOnTheDayConstraint(technician, d)])) {
                 errors = [...errors, ...PlanningContainer.validator.validationErrors]
                 return false
             }
             return true
        })

        const newAssignedSchedule = AssignedScheduleFactory.duplicateFromAssignedSchedule(assignedSchedule, d, validTechniciansToDuplicate)
        newAssignedSchedules.push(newAssignedSchedule)

        // Handle changes
        // Assigned Schedule change
        changes.push(createAssignedSchedulePlanningChange(ChangeType.Creation, newAssignedSchedule, newAssignedSchedule))
        newAssignedSchedule.assignments.forEach((assignment: Assignment) => {
            changes.push(createAssignmentPlanningChange(ChangeType.Creation, assignment, assignment))
        })
    })

    const updatedAssignedSchedules = addAssignedSchedules(state, newAssignedSchedules)
    const updatedChanges = addChanges(state, changes)

    return {
        changes: updatedChanges,
        assignedSchedules: updatedAssignedSchedules,
        errors: errors.length === 0 ? null : errors
    }
}