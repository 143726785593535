import Line from "../../model/line.interface";
import Technician from "../../model/technician.interface";
import Schedule from "../../model/schedule.interface";
import Competency from "../../model/competency.interface";
import AssignedSchedule from "../../model/assignedSchedule.interface";
import IPlanningChange from "../../model/planningChange.interface";

export default class PlanningLocalStorage {
    private _lines: Line[] = [];
    private _technicians: Technician[] = [];
    private _schedules: Schedule[] = [];
    private _competencies: Competency[] = [];
    private _assignedSchedules: AssignedSchedule[] = [];
    private _changes: IPlanningChange[] = [];


    get lines(): Line[] {
        return this._lines;
    }

    set lines(value: Line[]) {
        this._lines = value;
    }

    get technicians(): Technician[] {
        return this._technicians;
    }

    set technicians(value: Technician[]) {
        this._technicians = value;
    }

    get schedules(): Schedule[] {
        return this._schedules;
    }

    set schedules(value: Schedule[]) {
        this._schedules = value;
    }

    get competencies(): Competency[] {
        return this._competencies;
    }

    set competencies(value: Competency[]) {
        this._competencies = value;
    }

    get assignedSchedules(): AssignedSchedule[] {
        return this._assignedSchedules;
    }

    set assignedSchedules(value: AssignedSchedule[]) {
        this._assignedSchedules = value;
    }

    get changes(): IPlanningChange[] {
        return this._changes;
    }

    set changes(value: IPlanningChange[]) {
        this._changes = value;
    }
}