import Week from "../week.interface";
import {DataTransformer, InputDataTransformer} from "../../../Generic/dataTransformer/dataTransformer.interface";
import { DateUtils } from "../../../Generic/libraries/dateManipulation";

export interface InputWeek extends InputDataTransformer {
    year: number,
    number: number,
    days: string[],
    monday: string
}

export const weekDataTransformer: DataTransformer<Week> = {

    transform: (inputWeek: InputWeek): Week => {
        return {
            year: inputWeek.year,
            number: inputWeek.number,
            days: inputWeek.days.map((d: string): Date => DateUtils.setHoursToH(new Date(d), 12)),
            monday: new Date(inputWeek.monday)
        };
    }
}
