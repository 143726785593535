import {DataTransformer, InputDataTransformer} from "../../../Generic/dataTransformer/dataTransformer.interface";
import Competency from "../competency.interface";

export interface InputCompetency extends InputDataTransformer {
    id: number,
    name: string,
    linesId: number[]
}

export const competencyDataTransformer: DataTransformer<Competency> = {

    transform: (inputCompetency: InputCompetency): Competency => {
        return {
            id: inputCompetency.id,
            name: inputCompetency.name,
            linesId: inputCompetency.linesId
        };
    }
}
