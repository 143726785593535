import AssignedSchedule, { isSameAssignedSchedule } from "../../model/assignedSchedule.interface"
import Assignment from "../../model/assignment.interface"
import { getAssignedScheduleForAssignment } from "../../repository/State/stateAssignedSchedulesRepository"
import { PlanningState } from "../state"


export function updateAssignmentInAssignedSchedule(state: PlanningState, newAssignment: Assignment, assignedSchedule: AssignedSchedule | null = null): AssignedSchedule {
    let updatedAssignedSchedule: AssignedSchedule = assignedSchedule === null ? {...getAssignedScheduleForAssignment(state, newAssignment)} : {...assignedSchedule}

    updatedAssignedSchedule.assignments = updatedAssignedSchedule.assignments.map((a: Assignment) => {
        if (a.frontId === newAssignment.frontId && a.id === newAssignment.id) {
            return newAssignment
        }
        return a
    })

    return updatedAssignedSchedule
}

export function getUpdatedAssignedSchedulesFromAssignments(state: PlanningState, assignments: Assignment[]): AssignedSchedule[] {
    let assignedSchedules: AssignedSchedule[] = []

    assignments.forEach((a: Assignment) => {
        let uAS = getAssignedScheduleToUpdateForAssignment(state, a, assignedSchedules)
        uAS = updateAssignmentInAssignedSchedule(state, a, uAS)
        assignedSchedules = addAssignedSchedulesIntoAssignedSchedules(uAS, assignedSchedules)
    })
    
    return assignedSchedules
}

function getAssignedScheduleToUpdateForAssignment(state: PlanningState, assignment: Assignment, assignedSchedules: AssignedSchedule[]): AssignedSchedule {
    const stateAssignedSchedule = getAssignedScheduleForAssignment(state, assignment)
    const as = assignedSchedules.filter((a: AssignedSchedule) => isSameAssignedSchedule(a, stateAssignedSchedule))

    return as.length === 0 ? stateAssignedSchedule : as[0]
}

function addAssignedSchedulesIntoAssignedSchedules(assignedSchedule: AssignedSchedule, assignedSchedules: AssignedSchedule[]): AssignedSchedule[] {
    const existingAssignedSchedule: boolean = assignedSchedules.filter((a: AssignedSchedule) => isSameAssignedSchedule(a, assignedSchedule)).length > 0

    if (existingAssignedSchedule) {
        return [...assignedSchedules.map((a: AssignedSchedule) => isSameAssignedSchedule(a, assignedSchedule) ? assignedSchedule : a)]
    }  else {
        return [...assignedSchedules, assignedSchedule]
    }
    
}