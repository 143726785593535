import Assignment from "./assignment.interface";
import Technician from "./technician.interface";
import {isString} from "../../Generic/libraries/string";
import AssignedSchedule from "./assignedSchedule.interface";

export default interface Replacement {
    id: number | null,
    frontId: string,
    assignmentReplaced: string | number | null, //string if frontId, number si backendId
    assignmentReplacing: string | number | null, //string if frontId, number si backendId
    comment: string,
    replaced: Technician,
    replacing: Technician
}

export function isReplacement(elem: any): elem is Replacement {
    return !isString(elem) && elem !== null;
}

export function getReplacementId(a: Replacement): string|number {
    return a.id !== null ? a.id : a.frontId
}

export function isReplacementLinkedToAssignment(replacement: Replacement, assignment: Assignment): boolean {
    const isAssignmentReplaced = replacement.assignmentReplaced !== null && ((isString(replacement.assignmentReplaced) && replacement.assignmentReplaced === assignment.frontId) || (replacement.assignmentReplaced === assignment.id))

    const isAssignmentReplacing = replacement.assignmentReplacing !== null && ((isString(replacement.assignmentReplacing) && replacement.assignmentReplacing === assignment.frontId) || (replacement.assignmentReplacing === assignment.id))

    return isAssignmentReplaced || isAssignmentReplacing
}