import React from "react";
import Technician from "../../model/technician.interface";

type OperatorTechnicianCellProps = {
    technician: Technician
}

export function OperatorTechnicianCell({technician}: OperatorTechnicianCellProps) {
    return (
        <div className='indexCellContainer'>
            <div className='indexCellContent'>{technician.trigram}</div>
        </div>
    );
}