import {PlanningState} from "../state";
import {UpdateState} from "./updateState.interface";
import Assignment, {isSameAssigment} from "../../model/assignment.interface";
import AssignedSchedule from "../../model/assignedSchedule.interface";
import {createAssignmentPlanningChange} from "../../model/Factory/PlanningChangeFactory";
import {ChangeType} from "../../model/planningChange.interface";
import { addChanges } from "../../repository/State/stateChangesRepository";
import { updateAssignedSchedules } from "../../repository/State/stateAssignedSchedulesRepository";

export default function moveAssignmentToAssignedSchedule(state: PlanningState, assignment: Assignment, fromAssignedSchedule: AssignedSchedule, toAssignedSchedule: AssignedSchedule ): UpdateState | null {
    // add the change to the change list
    let updatedAssignment: Assignment = {...assignment, assignedScheduleId: toAssignedSchedule.id, assignedScheduleFrontId: toAssignedSchedule.frontId}
    let updatedChanges = addChanges(state, [createAssignmentPlanningChange(ChangeType.Update, updatedAssignment, assignment)])

    // update the Assigned Schedules
    let updatedFromAssignedSchedule = removeAssignmentFromAssignedSchedule(updatedAssignment, fromAssignedSchedule)
    let updatedToAssignedSchedule = addAssignmentToAssignedSchedule(updatedAssignment, toAssignedSchedule)

    let updatedAssignedSchedules = updateAssignedSchedules(state, [updatedFromAssignedSchedule, updatedToAssignedSchedule])

    return {
        changes: updatedChanges,
        assignedSchedules: updatedAssignedSchedules
    }
}

function addAssignmentToAssignedSchedule(assignment: Assignment, assignedSchedule: AssignedSchedule): AssignedSchedule {
    return {...assignedSchedule, assignments: [...assignedSchedule.assignments, assignment]}
}

function removeAssignmentFromAssignedSchedule(assignment: Assignment, assignedSchedule: AssignedSchedule): AssignedSchedule {
    let updatedAssignments = assignedSchedule.assignments.filter((a: Assignment) => {
        return !isSameAssigment(a, assignment)
    })

    return {...assignedSchedule, assignments: updatedAssignments}
}
