import Qualification from "./qualification.interface";

export enum TechnicianStatus {
    INTERIM = 'interim',
    INTERNAL = 'internal'
}

export enum WeekPlanningMode {
    ALL = 'all',
    MORNING = 'morning',
    EVENING = 'evening'
}

export const AlternatingPlanningModes = [WeekPlanningMode.MORNING, WeekPlanningMode.EVENING]


export default interface Technician {
    id: number,
    trigram : string,
    active: boolean,
    inactiveDays: Date[],
    qualifications: Qualification[],
    status: TechnicianStatus,
    planningMode: WeekPlanningMode
}
