import {PlanningState} from "../state";
import AssignedSchedule from "../../model/assignedSchedule.interface";
import {UpdateState} from "./updateState.interface";
import {
    createAssignedSchedulePlanningChange,
    createAssignmentPlanningChange,
    createReplacementPlanningChange
} from "../../model/Factory/PlanningChangeFactory";
import IPlanningChange, {ChangeType} from "../../model/planningChange.interface";
import {addChanges} from "../../repository/State/stateChangesRepository";
import {updateAssignedSchedules} from "../../repository/State/stateAssignedSchedulesRepository";
import Assignment, {isSameAssigment} from "../../model/assignment.interface";
import {isReplacement} from "../../model/replacement.interface";
import {PlanningContainer} from "../container";
import {findOneAssignment} from "../../repository/State/stateAssignmentRepository";
import {updateAssignmentInAssignedSchedule} from "./actionAssignedSchedulesHandling";

export default function updateAssignedSchedule(state: PlanningState, updatedAssignedSchedule: AssignedSchedule, oldAssignedSchedule: AssignedSchedule): UpdateState | null {
    let changes: IPlanningChange[] = [createAssignedSchedulePlanningChange(ChangeType.Update,updatedAssignedSchedule, oldAssignedSchedule)];

    //check if removed assignments
    let updatedAssignedSchedulesFromRemovedReplacements: AssignedSchedule[] = []
    const removedAssignments = getRemovedAssignments(updatedAssignedSchedule.assignments, oldAssignedSchedule.assignments)
    removedAssignments.forEach((a: Assignment) => {
        //remove also the replacements
        if (a.replaced || a.replacing) {
            const updates = removeReplacementLinkedToAssignment(a, state)
            changes = [...changes, ...updates.changes]
            updatedAssignedSchedulesFromRemovedReplacements = [...updatedAssignedSchedulesFromRemovedReplacements, ...updates.assignedSchedules]
        }
        changes.push(createAssignmentPlanningChange(ChangeType.Deletion, a, a))
    })

    //check if added assignments
    const addedAssignments = getAddedAssignments(updatedAssignedSchedule.assignments, oldAssignedSchedule.assignments)
    addedAssignments.forEach((a: Assignment) => {
        changes.push(createAssignmentPlanningChange(ChangeType.Creation, a, a))
    })

    let updatedChanges = addChanges(state, changes)

    let updatedAssignedSchedules = updateAssignedSchedules(state, [updatedAssignedSchedule, ...updatedAssignedSchedulesFromRemovedReplacements])
    
    return {
        changes: updatedChanges,
        assignedSchedules: updatedAssignedSchedules
    }
}

function getRemovedAssignments(newAssignments: Assignment[], oldAssignments: Assignment[]): Assignment[] {
    return oldAssignments.filter((oldA: Assignment) => {
        return newAssignments.filter((newA: Assignment) => isSameAssigment(newA, oldA)).length === 0 //true if oldAssignment not in newAssigments
    })
}

function getAddedAssignments(newAssignments: Assignment[], oldAssignments: Assignment[]): Assignment[] {
    return newAssignments.filter((newA: Assignment) => {
        return oldAssignments.filter((oldA: Assignment) => isSameAssigment(newA, oldA)).length === 0 //true if newAssignment not in oldAssignments
    })
}

function removeReplacementLinkedToAssignment(assignment: Assignment, state: PlanningState): UpdateState {
    let changes: IPlanningChange[] = []
    let assignedSchedules: AssignedSchedule[] = []

    if (isReplacement(assignment.replacedReplacement)) {
        changes.push(createReplacementPlanningChange(ChangeType.Deletion, assignment.replacedReplacement, assignment.replacedReplacement))
        let replacingAssignment = findOneAssignment(state, assignment.replacedReplacement.assignmentReplacing)
        replacingAssignment = {
            ...replacingAssignment,
            replacing: false,
            replacingReplacement: null
        }

        assignedSchedules.push(updateAssignmentInAssignedSchedule(state, replacingAssignment))
    }

    if (isReplacement(assignment.replacingReplacement)) {
        changes.push(createReplacementPlanningChange(ChangeType.Deletion, assignment.replacingReplacement, assignment.replacingReplacement))
        let replacedAssignment = findOneAssignment(state, assignment.replacingReplacement.assignmentReplaced)
        replacedAssignment = {
            ...replacedAssignment,
            replaced: false,
            replacedReplacement: null
        }

        assignedSchedules.push(updateAssignmentInAssignedSchedule(state, replacedAssignment))
    }

    return {changes, assignedSchedules}
}