import {PlanningState} from "../state";
import AssignedSchedule from "../../model/assignedSchedule.interface";
import {UpdateState} from "./updateState.interface";
import {
    createAssignedSchedulePlanningChange,
    createAssignmentPlanningChange
} from "../../model/Factory/PlanningChangeFactory";
import IPlanningChange, {ChangeType} from "../../model/planningChange.interface";
import {addChanges} from "../../repository/State/stateChangesRepository";
import {addAssignedSchedules} from "../../repository/State/stateAssignedSchedulesRepository";
import Assignment from "../../model/assignment.interface";

export function createNewAssignedSchedule(state: PlanningState, newAssignedSchedule: AssignedSchedule): UpdateState {
    //create change for new Assigned Schedule
    let changes: IPlanningChange[] = [createAssignedSchedulePlanningChange(ChangeType.Creation, newAssignedSchedule, newAssignedSchedule)]

    //add one change for each assignment
    changes = [...changes, ...newAssignedSchedule.assignments.map((a: Assignment) => {
        return createAssignmentPlanningChange(ChangeType.Creation, a, a)
    })]

    // update change list
    const updatedChanges = addChanges(state, changes)

    // update assignedSchedules list
    const updatedAssignedSchedules = addAssignedSchedules(state, [newAssignedSchedule])

    return {
        changes: updatedChanges,
        assignedSchedules: updatedAssignedSchedules
    }
}