import {DataTransformer, InputDataTransformer} from "../../../Generic/dataTransformer/dataTransformer.interface";
import Schedule from "../schedule.interface";
import {WeekPlanningMode} from "../technician.interface";

export interface InputSchedule extends InputDataTransformer {
    id: number,
    name: string,
    startTime: string,
    endTime: string,
    dayPosition: string,
    planningMode: string
}

export const scheduleDataTransformer: DataTransformer<Schedule> = {

    transform: (inputSchedule: InputSchedule): Schedule => {
        return {
            id: inputSchedule.id,
            name: inputSchedule.name,
            dayPosition: inputSchedule.dayPosition,
            startTime: new Date(inputSchedule.startTime),
            endTime: new Date(inputSchedule.endTime),
            planningMode: inputSchedule.planningMode as WeekPlanningMode
        };
    }
}