import {DataTransformer, InputDataTransformer} from "../../../Generic/dataTransformer/dataTransformer.interface";
import Assignment from "../assignment.interface";
import {InputTechnician} from "./technicianDataTransformer";
import Replacement from "../replacement.interface";
import {replacementDataTransformer} from "./replacementDataTransformer";
import { DateUtils } from "../../../Generic/libraries/dateManipulation";

export interface InputAssignment extends InputDataTransformer {
    id: number,
    day: string,
    replaced: boolean,
    replacing: boolean,
    replacedReplacement: Replacement | null,
    replacingReplacement: Replacement | null,
    technician: InputTechnician,
    schedule: string,
    updatedAt: string
}

export const assignmentDataTransformer: DataTransformer<Assignment> = {

    transform: (inputAssignment: InputAssignment): Assignment => {
        const replacedReplacement: Replacement | null = getReplacement(inputAssignment.replacedReplacement)
        const replacingReplacement: Replacement | null = getReplacement(inputAssignment.replacingReplacement)

        return {
            id: inputAssignment.id,
            frontId: null,
            replacing: inputAssignment.replacing,
            replaced: inputAssignment.replaced,
            assignedScheduleId:  parseInt(inputAssignment.schedule),
            assignedScheduleFrontId: null,
            technicianId: inputAssignment.technician.id,
            day: DateUtils.setHoursToH(new Date(inputAssignment.day), 12),
            updatedAt: new Date(inputAssignment.updatedAt),
            changed: false,
            replacedReplacement,
            replacingReplacement
        };
    }
}

const getReplacement = (replacement: Replacement | string | null): Replacement | null => {
    if (replacement === null) {
        return null
    } else {
        return replacementDataTransformer.transform(replacement)
    }
}
