import {PlanningState} from "../state";
import {UpdateState} from "./updateState.interface";
import Assignment, { getAssignmentId, isSameAssigment } from "../../model/assignment.interface";
import { ReplacementFactory } from "../../model/Factory/replacementFactory";
import {  updateAssignedSchedules } from "../../repository/State/stateAssignedSchedulesRepository";
import { createAssignmentPlanningChange, createReplacementPlanningChange } from "../../model/Factory/PlanningChangeFactory";
import IPlanningChange, { ChangeType } from "../../model/planningChange.interface";
import { addChanges } from "../../repository/State/stateChangesRepository";
import Replacement, { isReplacement } from "../../model/replacement.interface";
import { PlanningContainer } from "../container";
import { findOneAssignment } from "../../repository/State/stateAssignmentRepository";
import { getUpdatedAssignedSchedulesFromAssignments, updateAssignmentInAssignedSchedule } from "./actionAssignedSchedulesHandling";

export default function saveReplacement(state: PlanningState, assignment: Assignment, replacementAssignment: Assignment, comment: string): UpdateState | null {
    if (!isReplacement(assignment.replacedReplacement)) {
        return createReplacement(state, assignment, replacementAssignment, comment)
    } 

    return updateReplacement(state, assignment, replacementAssignment, comment)
}

function createReplacement(state: PlanningState, assignment: Assignment, replacementAssignment: Assignment, comment: string): UpdateState | null {

    //1. Create replacement
    const replacement = ReplacementFactory.create(assignment, replacementAssignment, comment)

    //2. Update Assignments & update AssignedSchedule
    let replacedAssignment: Assignment = {
        ...assignment,
        replaced: true,
        replacedReplacement: replacement
    }

    let replacingAssignment: Assignment = {
        ...(getAssignmentId(assignment) === getAssignmentId(replacementAssignment) ? replacedAssignment : replacementAssignment), //check if both assignments are the same
        replacing: true,
        replacingReplacement: replacement
    }

    return {
        changes: addChanges(state, [createReplacementPlanningChange(ChangeType.Creation, replacement, replacement)]),
        assignedSchedules: updateAssignedSchedules(state, [
            updateAssignmentInAssignedSchedule(state, replacedAssignment), 
            updateAssignmentInAssignedSchedule(state, replacingAssignment)
        ])
    }
}

function updateReplacement(state: PlanningState, assignment: Assignment, replacingAssignment: Assignment, comment: string): UpdateState | null {
    if (!isReplacement(assignment.replacedReplacement)) {
        return
    }
    const originalReplacement: Replacement = {...assignment.replacedReplacement}
    const originalAssignmentReplacing = findOneAssignment(state, originalReplacement.assignmentReplacing)

    // 1. Update replacement
    const updatedReplacement: Replacement = {
        ...originalReplacement,
        assignmentReplacing: replacingAssignment.id !== null ? replacingAssignment.id : replacingAssignment.frontId,
        replacing: PlanningContainer.technicianRepository.findOne(replacingAssignment.technicianId),
        comment
    }
    
    let changes: IPlanningChange[] = [createReplacementPlanningChange(ChangeType.Update, updatedReplacement, originalReplacement)]
    let updatedAssignments: Assignment[] = []

    // Handle if old replacing assignment is not any of the new assignments in the replacement
    if (!isSameAssigment(originalAssignmentReplacing, assignment) && !isSameAssigment(originalAssignmentReplacing, replacingAssignment)) {
        const updatedOriginalReplacingAssignment = {
            ...originalAssignmentReplacing,
            replacing: false,
            replacingReplacement: null
        }
        updatedAssignments.push(updatedOriginalReplacingAssignment)
        changes.push(createAssignmentPlanningChange(ChangeType.Update, updatedOriginalReplacingAssignment, originalAssignmentReplacing))
    }

    //Handle if both assignment and replacing assignment are the same
    if (isSameAssigment(assignment, replacingAssignment)) {
        const updatedAssignment: Assignment = {
            ...assignment,
            replaced: true,
            replacing: true,
            replacedReplacement: updatedReplacement,
            replacingReplacement: updatedReplacement
        }
        updatedAssignments.push(updatedAssignment)
        changes.push(createAssignmentPlanningChange(ChangeType.Update, updatedAssignment, assignment))
    } 
    //Handle if not the same assignments
    else {
        const updatedAssignment: Assignment = {
            ...assignment,
            replaced: true,
            replacedReplacement: updatedReplacement,
        }
        const updatedReplacingAssignment: Assignment =  {
            ...replacingAssignment,
            replacing: true,
            replacingReplacement: updatedReplacement
        }

        updatedAssignments = [...updatedAssignments, ...[updatedAssignment, updatedReplacingAssignment]]
        changes = [...changes, 
            createAssignmentPlanningChange(ChangeType.Update, assignment, updatedAssignment),
            createAssignmentPlanningChange(ChangeType.Update, updatedReplacingAssignment, replacingAssignment)
        ]
    }
        
    return {
        changes: addChanges(state, changes),
        assignedSchedules: updateAssignedSchedules(state, getUpdatedAssignedSchedulesFromAssignments(state, updatedAssignments))
    }
}