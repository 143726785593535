import BackendRepository from "./backendRepository";
import Line from "../model/line.interface";
import IRepository from "./repository.interface";
import PlanningLocalStorage from "./Storage/planningLocalStorage";
import {sortBy} from "../../Generic/libraries/sort";

export default class LineRepository implements IRepository<Line>{
    localRepo: PlanningLocalStorage

    constructor(localRepo: PlanningLocalStorage) {
        this.localRepo = localRepo;
    }

    public findAll(orderBy: string = null): Line[] {
        if (orderBy === null) {
            return this.localRepo.lines;
        }

        return sortBy([...this.localRepo.lines], 'number');
    }

    public findOne(lineId: number): Line|null {
        const line = this.localRepo.lines.filter((l: Line) => {
            return l.id === lineId
        })

        if (line.length === 1) {
            return line[0];
        } else {
            return null
        }
    }
}