import AssignedSchedule from "../assignedSchedule.interface";
import Line from "../line.interface";
import {create_UUID} from "../../../Generic/libraries/uuid";
import Technician from "../technician.interface";
import {AssignmentFactory} from "./assignmentFactory";
import Schedule from "../schedule.interface";
import {PlanningContainer} from "../../context/container";

export const AssignedScheduleFactory = {
    create: (line: Line, day: Date, schedule: Schedule = null, technicians: Technician[] = []): AssignedSchedule =>{
        const tempAssignedSchedule = {
            id: null,
            frontId: create_UUID(),
            lineId: line.id,
            preferenceCapacity: line.capacity,
            day: day,
            scheduleId: schedule?.id ?? null,
            assignments: [],
            incompleteSolution: false,
        }

        return {
            ...tempAssignedSchedule,
            assignments: technicians.map((t: Technician) => AssignmentFactory.create(day, t.id, tempAssignedSchedule))
        }
    },

    duplicateFromAssignedSchedule: (assignedSchedule: AssignedSchedule, day: Date, technicians: Technician[]): AssignedSchedule => {
        const line = PlanningContainer.lineRepository.findOne(assignedSchedule.lineId)
        const schedule = PlanningContainer.scheduleRepository.findOne(assignedSchedule.scheduleId)
        return AssignedScheduleFactory.create(line, day, schedule, technicians)
    },

    createIncompleteAssignedSchedule: (assignedSchedule: AssignedSchedule, technicians: Technician[]): AssignedSchedule => {
        let incompleteAssignedSchedule = {...assignedSchedule}
        technicians.forEach((tempTechnician: Technician) => {
            let assignment = AssignmentFactory.create(
                incompleteAssignedSchedule.day, tempTechnician.id, assignedSchedule
            )
            assignedSchedule.assignments.push(assignment)
        });

        return incompleteAssignedSchedule;
    }
}
