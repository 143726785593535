import {DataTransformer, InputDataTransformer} from "../../../Generic/dataTransformer/dataTransformer.interface";
import DemandWeek from "../demandWeek.interface";
import Solution from "../solution.interface";
import {solutionDataTransformer} from "./solutionDataTransformer";

export interface InputDemandWeek extends InputDataTransformer {
    demandCompleted: boolean,
    solution : Solution
}

export const demandWeekDataTransformer: DataTransformer<DemandWeek> = {

    transform: (inputDemandWeek: InputDemandWeek): DemandWeek => {
        return {
            demandCompleted: inputDemandWeek.demandCompleted,
            solution: solutionDataTransformer.transform(inputDemandWeek.solution)
        };
    }
}