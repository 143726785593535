import Assignment from "../assignment.interface";
import {create_UUID} from "../../../Generic/libraries/uuid";
import Replacement from "../replacement.interface";
import { PlanningContainer } from "../../context/container";

export const ReplacementFactory = {
    create: (assignment: Assignment, replacingAssignment: Assignment, comment: string): Replacement => {

        const replaced = PlanningContainer.technicianRepository.findOne(assignment.technicianId)
        const replacing = PlanningContainer.technicianRepository.findOne(replacingAssignment.technicianId)

        return {
            id: null,
            frontId: create_UUID(),
            assignmentReplaced: assignment.id !== null ? assignment.id : assignment.frontId,
            assignmentReplacing: replacingAssignment.id !== null ? replacingAssignment.id : replacingAssignment.frontId,
            comment,
            replaced,
            replacing
        }
    }
}