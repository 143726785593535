import IRepository from "./repository.interface";
import Technician, {TechnicianStatus} from "../model/technician.interface";
import PlanningLocalStorage from "./Storage/planningLocalStorage";

export default class TechnicianRepository implements IRepository<Technician>{
    localRepo: PlanningLocalStorage

    constructor(localRepo: PlanningLocalStorage) {
        this.localRepo = localRepo;
    }

    public findAll(): Technician[] {
        return this.localRepo.technicians;
    }

    public findAllInterim(): Technician[] {
        return this.localRepo.technicians.filter((technician: Technician): boolean => {
            return technician.status === TechnicianStatus.INTERIM

        }) 
    }

    public findOne(technicianId: number): Technician|null {
        const technician = this.localRepo.technicians.filter((t: Technician) => {
            return t.id === technicianId
        })

        if (technician.length === 1) {
            return technician[0];
        } else {
            return null
        }
    }
}
