import {DateUtils} from "../../Generic/libraries/dateManipulation";

export default interface Week {
    year: number,
    number: number,
    days: Date[],
    monday?: Date
}

function getDateWeek (d: Date) {
    const startOfTheYear = new Date(d.getFullYear(), 0, 4);
    // @ts-ignore
    return Math.ceil((((d - startOfTheYear) / 86400000) + startOfTheYear.getDay() + 1) / 7);
}

export const initWeek = (weekYear: string = '', weekNumber: string = '') : Week => {
    const currentDate = new Date();
    const monday = DateUtils.getMondayOfDate(currentDate)
    let days: Date[] = []
    for (let i: number = 0; i<7; i++) {
        days.push(DateUtils.addDays(monday,i))
    }
    return {
        year: weekYear === '' ? currentDate.getFullYear() : parseInt(weekYear),
        number: weekNumber === '' ? getDateWeek(currentDate) : parseInt(weekNumber),
        days
    }
}