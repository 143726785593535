import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {CSSProperties, useContext, useMemo} from "react";
import {hexToRgb, lightOrDark} from "../../../Generic/libraries/colors";
import {PlanningContainer} from "../../context/container";
import useTranslation from "../../../Generic/hooks/useTranslation";
import {isReplacement} from "../../model/replacement.interface";
import {TableDataTechnicianDay} from "./operatorPlanningTable";
import {PlanningContext} from "../../context/PlanningContextProvider";
import useValidator from "../../../Generic/hooks/useValidator";
import {
    NotSamePlanningModeForTechnicianAndScheduleConstraint
} from "../../validation/constraints/NotSamePlanningModeForTechnicianAndScheduleConstraint";
import {classNames} from "../../../Generic/libraries/style";

type PlanningCellOperatorAssignmentProps = {
    cellData: TableDataTechnicianDay
}

export function PlanningCellOperatorAssignment({cellData}: PlanningCellOperatorAssignmentProps) {
    const {state} = useContext(PlanningContext)
    const {trans} = useTranslation()
    const {validate} = useValidator()

    let assignmentLine = PlanningContainer.lineRepository.findOne(cellData.assignedSchedule?.lineId)
    const assignmentTechnician = PlanningContainer.technicianRepository.findOne(cellData.assignment?.technicianId)
    const assignmentSchedule = PlanningContainer.scheduleRepository.findOne(cellData.assignedSchedule?.scheduleId)

    const isReplaced = cellData.assignment !== undefined && cellData.assignment.replaced
    const isSelfReplacing = isReplaced
        && isReplacement(cellData.assignment?.replacedReplacement)
        && isReplacement(cellData.assignment?.replacingReplacement)
        && cellData.assignment?.replacedReplacement.replacing.trigram === cellData.assignment?.replacedReplacement.replaced.trigram;
    const isReplacing = cellData.assignment?.replacing
    const isAssigned = cellData.type === 'assignment'
    const isInactiveDay = cellData.type === 'inactiveDay'

    const isNotSamePlanningMode = useMemo<boolean>(() => {
        if (cellData.assignedSchedule === undefined) {
            return false
        }
        return validate([new NotSamePlanningModeForTechnicianAndScheduleConstraint(cellData.assignedSchedule.scheduleId, cellData.technician)])
    }, [])

    let className = 'PlanningCellContent' + (isAssigned?' assigned': (isInactiveDay? ' inactiveDay' : ' noAssignmentsDay'))
     className = classNames(
        className,
        isNotSamePlanningMode ? 'notSamePlanningMode' : ''
    )

    const backgroundColor = useMemo<CSSProperties>(() => {
        let color = {};
        if (isAssigned){
            const {r,g,b} = hexToRgb(assignmentLine.color);
            color = {background: 'rgba('+r+','+g+','+b+', 0.7)'};
        } 
        return color
    }, [cellData])

    const textColor = useMemo<CSSProperties>(() => {
        let color = {color: '#000'}
        if (isAssigned) {
            color = {color: lightOrDark(backgroundColor.background) === 'dark' ? '#fff' : 'inherit' }
        }
        return color;
    }, [backgroundColor, isAssigned])
    
    /************
     * Actions
     */
    const onReplacementClick = () => {
        //props.assignmentActions.onAssignmentClick(props.assignment, props.assignedSchedule);
    }

    return (
        <div className={className+ (state.print? ' print' : '')} style={{...backgroundColor, ...textColor}}>
            {isAssigned && (
                <div className='dayCellContent dayCellOperatorAssignmentContent'>
                    <p className='scheduleName'>{isNotSamePlanningMode && (<FontAwesomeIcon icon={faExclamationTriangle} className="mr-3" color="red"/>)} {assignmentSchedule.name}</p>
                    <p className='lineName'>({assignmentLine.displayName})</p>
                </div>
            )}
            {isInactiveDay && (
                <div className='dayCellContent dayCellOperatorAssignmentContent'>
                    <span className='dayOff'>{trans('operatorPlanning.cell.dayOff.content')}</span>
                </div>
            )}
            {(isReplaced && isSelfReplacing) && (
                <div className='dayCellContent dayCellReplacement' onClick={onReplacementClick}>
                    <FontAwesomeIcon icon={faExclamationTriangle} /> {assignmentTechnician.trigram}
                </div>
            )}
            {(isReplaced && !isSelfReplacing) && (
                <div className='dayCellContent dayCellReplacement' onClick={onReplacementClick}>
                    {assignmentTechnician.trigram} {'<'} {isReplacement(cellData.assignment.replacedReplacement) ? cellData.assignment.replacedReplacement.replacing.trigram : cellData.assignment.replacedReplacement}
                </div>
            )}
            {(isReplacing && !isSelfReplacing) && (
                <div className='dayCellContent dayCellReplacement' onClick={onReplacementClick}>
                    {assignmentTechnician.trigram} {'>'} {isReplacement(cellData.assignment.replacingReplacement) ? cellData.assignment.replacingReplacement.replaced.trigram : cellData.assignment.replacingReplacement}
                </div>
            )}
        </div>
    )
}
