import {Constraint} from "../../../Generic/validation/constraint.interface";
import Technician from "../../model/technician.interface";
import AssignedSchedule from "../../model/assignedSchedule.interface";
import {PlanningContainer} from "../../context/container";
import {TechnicianService} from "../../model/Service/technicianService";
import Assignment from "../../model/assignment.interface";
import {isReplacement} from "../../model/replacement.interface";

export class AssignmentNotAlreadyReplacingConstraint implements Constraint {
    _replacedAssignment: Assignment
    _replacingAssignment: Assignment
    errorMessage: "The technician in the replacing assignment is already replacing someone else than the technician in the replaced assignment"

    constructor(replacedAssignment: Assignment, replacingAssignment: Assignment) {
        this._replacedAssignment = replacedAssignment
        this._replacingAssignment = replacingAssignment
    }

    isValid(): boolean {
        return !this._replacingAssignment.replacing //replacingAssignment is not yet replacing
            || ( //if replacing it is replacing the replaced one already
                isReplacement(this._replacingAssignment.replacingReplacement)
                && this._replacingAssignment.replacingReplacement.replaced.id === this._replacedAssignment.technicianId
            )
    }
}