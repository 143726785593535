import { sortBy } from "../../Generic/libraries/sort";
import Schedule from "../model/schedule.interface";
import IRepository from "./repository.interface";
import PlanningLocalStorage from "./Storage/planningLocalStorage";

export default class ScheduleRepository implements IRepository<Schedule>{
    localRepo: PlanningLocalStorage

    constructor(localRepo: PlanningLocalStorage) {
        this.localRepo = localRepo;
    }

    public findAll(): Schedule[] {
        return sortBy(this.localRepo.schedules, 'startTime');
    }

    public findOne(scheduleId: number): Schedule|null {
        const schedule = this.localRepo.schedules.filter((s: Schedule) => {
            return s.id === scheduleId
        })

        if (schedule.length === 1) {
            return schedule[0];
        } else {
            return null
        }
    }

    get
}