import {PlanningState} from "../../context/state";
import IPlanningChange, {
    AssignedSchedulePlanningChange,
    AssignmentPlanningChange,
    ChangeObjectType,
    ChangeType,
    getPlanningChangeDetailId,
    isAssignedSchedulePlanningChange,
    isAssignmentPlanningChange,
    isReplacementPlanningChange
} from "../../model/planningChange.interface";
import {getAssignedScheduleId} from "../../model/assignedSchedule.interface";
import {getAssignmentId, isAssigment} from "../../model/assignment.interface";
import {getReplacementId, isReplacement, isReplacementLinkedToAssignment} from "../../model/replacement.interface";
import {isString} from "lodash";
import {replaceElementBy} from "../../../Generic/libraries/array";

export const getChangesForObjectAndType= (state: PlanningState, objectType: ChangeObjectType, changeType: ChangeType): IPlanningChange[] => {
    return state.changes.filter((change: IPlanningChange) => {
        return change.objectType === objectType && change.type === changeType;
    })
}

/**
 * @return IPlanningChange[] : returns the full state changes list updated with the new changes
 */
export const addChanges = (state: PlanningState, changes: IPlanningChange[]): IPlanningChange[] => {
    return changes.reduce((updatedChanges: IPlanningChange[], change: IPlanningChange) => {
        return addChange(change, updatedChanges)
    }, [...state.changes])
}

function addChange(change: IPlanningChange, changeList: IPlanningChange[]): IPlanningChange[] {
    switch (change.type) {
        case ChangeType.Creation:
            return [...changeList, change]
        case ChangeType.Update:
            return addUpdateChange(change, changeList)
        case ChangeType.Deletion:
            return addDeletionChange(change, changeList)
        default:
            return changeList
    }
}

function addUpdateChange(change: IPlanningChange, changeList: IPlanningChange[]): IPlanningChange[] {
    if (change.id === null) { //change on a newly created element
        change.type = ChangeType.Creation
        return replaceElementBy([...changeList], change, 'frontId')
    } else { //change on an existing element in the backend
        const updatedChanges = changeList.filter((existingChange: IPlanningChange) => {
            return !(existingChange.type === ChangeType.Update
                && isSameChangeData(existingChange, change));
        })
        return [...updatedChanges, change]
    }
}

function addDeletionChange(change: IPlanningChange, changeList: IPlanningChange[]): IPlanningChange[] {
    let updatedChanges = [...changeList]

    if (isAssignedSchedulePlanningChange(change)) {
        updatedChanges = removeAllAssignedScheduleDeletionRelatedChanges(change, updatedChanges)
    }
    if (isAssignmentPlanningChange(change)) {
        updatedChanges = removeAllAssignmentDeletionRelatedChanges(change, updatedChanges)
    }

    if (isString(getPlanningChangeDetailId(change))) { //deletion is on newly created element ==> no addition as everything removed
        return updatedChanges
    } else {
        return [...updatedChanges, change]
    }
}

function isSameChangeData(changeA: IPlanningChange, changeB: IPlanningChange): boolean {
    if (changeA.objectType !== changeB.objectType) {
        return false
    }

    if (isAssignedSchedulePlanningChange(changeA) && isAssignedSchedulePlanningChange(changeB) && getAssignedScheduleId(changeA.originalDetails) !== getAssignedScheduleId(changeB.originalDetails)) {
        return false
    }

    if (isAssignmentPlanningChange(changeA) && isAssignmentPlanningChange(changeB) && getAssignmentId(changeA.originalDetails) !== getAssignmentId(changeB.originalDetails)) {
        return false
    }

    if (isReplacementPlanningChange(changeA) && isReplacementPlanningChange(changeB) && getReplacementId(changeA.originalDetails) !== getReplacementId(changeB.originalDetails)) {
        return false
    }

    return true
}

function removeAllAssignmentDeletionRelatedChanges(change: AssignmentPlanningChange, existingChanges: IPlanningChange[]): IPlanningChange[] {
    return existingChanges.filter((existingChange: IPlanningChange) => {
        const isSameAssignment = isSameChangeData(change, existingChange)

        const isReplacementLinkedToDeletedAssignment = existingChange.objectType == ChangeObjectType.Replacement
                                && isReplacement(existingChange.details) && isReplacementLinkedToAssignment(existingChange.details, change.originalDetails)

        return !(isSameAssignment || isReplacementLinkedToDeletedAssignment)
    })
}

function removeAllAssignedScheduleDeletionRelatedChanges(change: AssignedSchedulePlanningChange, existingChanges: IPlanningChange[]): IPlanningChange[] {
    return existingChanges.reduce((remainingChanges: IPlanningChange[], existingChange: IPlanningChange) => {
        if (!isAssigment(existingChange.originalDetails)) {
            return remainingChanges
        }

        if (!(existingChange.originalDetails.assignedScheduleId === change.originalDetails.id && existingChange.originalDetails.assignedScheduleFrontId === change.originalDetails.frontId)) {
            return remainingChanges
        }
        //handle existingChange only if Assignment and linked to changed AssignedSchedule
        let tempRemainingChanges = removeAllAssignmentDeletionRelatedChanges(existingChange as AssignmentPlanningChange, remainingChanges);

        return removeChangeFromList(existingChange, tempRemainingChanges)

    }, [...existingChanges])
}

function removeChangeFromList(change: IPlanningChange, changeList: IPlanningChange[]): IPlanningChange[] {
    return changeList.filter((existingChange: IPlanningChange) => !isSameChangeData(change, existingChange))
}