import { isString } from "../../Generic/libraries/string";
import Replacement from "./replacement.interface";

export default interface Assignment {
    id: number|null,
    frontId: string|null,
    assignedScheduleId: number|null
    assignedScheduleFrontId:string|null,
    technicianId: number,
    day: Date,
    replaced: boolean,
    replacing: boolean,
    replacedReplacement: Replacement | string | null,
    replacingReplacement: Replacement | string | null,
    updatedAt: Date,
    changed: boolean
}

export function isAssigment(a: any): a is Assignment {
    return !Number.isInteger(a) && !isString(a)
}

export function getAssignmentId(a: Assignment): string|number {
    return a.id !== null ? a.id : a.frontId
}

export function isSameAssigment(a: Assignment, b: Assignment): boolean {
    return getAssignmentId(a) === getAssignmentId(b)
}