import AssignedSchedule from "../model/assignedSchedule.interface";
import {PlanningContainer} from "../context/container";
import Line from "../model/line.interface";
import Assignment from "../model/assignment.interface";
import {TechnicianService} from "../model/Service/technicianService";
import Technician from "../model/technician.interface";
import { GenericValidator } from "../../Generic/validation/validator";
import { TechnicianIsTrainedForAssignedScheduleConstraint } from "../validation/constraints/TechnicianIsTrainedForAssignedSchedule.constraint";

const lineRepository = PlanningContainer.lineRepository

export const assignedScheduleRepository = {
    getPreferredCapacity: (assignedSchedule: AssignedSchedule): number => {
        return assignedSchedule.preferenceCapacity
    },

    getMaxCapacity: (assignedSchedule: AssignedSchedule): number => {
        const line: Line = lineRepository.findOne(assignedSchedule.lineId)

        return (line.topCapacity !== null)? line.topCapacity : assignedSchedule.preferenceCapacity;
    },

    getMinCapacity: (assignedSchedule: AssignedSchedule): number => {
        const line: Line = lineRepository.findOne(assignedSchedule.lineId)

        return line.capacity;
    },

    getUsedCapacity: (assignedSchedule: AssignedSchedule): number => {
        const validator = new GenericValidator();
        
        return assignedSchedule.assignments.filter((a: Assignment) => {
            return validator.validate([new TechnicianIsTrainedForAssignedScheduleConstraint(a.technicianId, assignedSchedule)])
        }).length;
    },

    hasAtLeastOneQualifiedTechnicians: (assignedSchedule: AssignedSchedule): boolean => {
        return assignedSchedule.assignments.filter((a: Assignment) => {
            return TechnicianService.isTechnicianQualifiedForLine(PlanningContainer.technicianRepository.findOne(a.technicianId), PlanningContainer.lineRepository.findOne(assignedSchedule.lineId))
        }).length >= 1
    },

    getAssignedTechnicians: (assignedSchedule: AssignedSchedule): Technician[] => {
        return assignedSchedule.assignments.map((a: Assignment) => PlanningContainer.technicianRepository.findOne(a.technicianId))
    }
}
