import {DataTransformer, InputDataTransformer} from "../../../Generic/dataTransformer/dataTransformer.interface";
import { PlanningContainer } from "../../context/container";
import Qualification from "../qualification.interface";

export interface InputQualification extends InputDataTransformer {
    id: number,
    level: string,
    competency:number 
}

export const qualificationDataTransformer: DataTransformer<Qualification> = {

    transform: (inputQualification: InputQualification): Qualification => {
        return {
            id: inputQualification.id,
            level: inputQualification.level,
            competency: PlanningContainer.competencyRepository.findOne(inputQualification.competency)
        };
    }
}
