import Competency from "./competency.interface";

export default interface Qualification {
    id: number,
    level: string,
    competency: Competency
}

export const QualificationLevel = {
  'trained': 'F',
  'qualified': 'Q',
  'in_training': 'TF',
  'in_qualification': 'TQ'
}
