import {useContext, useMemo, useRef} from "react";
import {useDrag, useDrop} from "react-dnd";
import {PlanningContext} from "../../context/PlanningContextProvider";
import Assignment, {isSameAssigment} from "../../model/assignment.interface";
import AssignedSchedule, {isSameAssignedSchedule} from "../../model/assignedSchedule.interface";
import {DateUtils} from "../../../Generic/libraries/dateManipulation";
import {TechnicianService} from "../../model/Service/technicianService";
import {PlanningCellOperatorAssignment} from "./planningCellOperatorAssignment";
import Technician from "../../model/technician.interface";
import {TableDataTechnicianDay} from "./operatorPlanningTable";
import {
    SamePlanningModeForTechnicianAndScheduleConstraint
} from "../../validation/constraints/SamePlanningModeForTechnicianAndScheduleConstraint";
import {BooleanConstraint} from "../../../Generic/validation/constraint/BooleanConstraint";
import useValidator from "../../../Generic/hooks/useValidator";

type PlanningCellOperatorProps = {
    cellData: TableDataTechnicianDay
}

type AssignmentDragAndDropItemType = {
    assignment: Assignment,
    assignedSchedule: AssignedSchedule,
    technician: Technician
}

export function PlanningCellOperator({cellData}: PlanningCellOperatorProps) {
    const {state, actions} = useContext(PlanningContext)
    const {validate} = useValidator()

    const targetIsAssignment = useMemo<boolean>(() => cellData.type === 'assignment', [cellData.type])

    /******************************
     * Actions
     */

    const onAssignmentMove = (item: AssignmentDragAndDropItemType) => {
        actions.changeAssignmentTechnician(item.assignment, cellData.technician)

    }

    const onAssignmentSwap = (item: AssignmentDragAndDropItemType) => {
        actions.swapAssignments(item.assignment, item.assignedSchedule, cellData.assignment, cellData.assignedSchedule)
    }

    /**************************
     * Drag and Drop
     */
    const ref = useRef(null)
    let additionalClassName = ''
    if (!state.print) {
       //Draggability
       const [, drag] = useDrag<AssignmentDragAndDropItemType>({
           type: 'assignment',
           item: { assignment: cellData.assignment, assignedSchedule: cellData.assignedSchedule, technician: cellData.technician },
           canDrag: (_monitor) => {
               if (!state.isLogged){
                   return false;
               }
               return cellData.type === 'assignment'
           }
       })
   
       //Dropability
       const [{ didDrop, draggedItem, canDrop }, drop] = useDrop<AssignmentDragAndDropItemType, any, any>({
           accept: 'assignment',
           drop: (item, _monitor)  => {
               if (!targetIsAssignment){
                   onAssignmentMove(item);
               } else {
                   onAssignmentSwap(item);
               }
           },
           /**
            * item = item being dropped
            * cellData = cell data being dropped on
            */
           canDrop: (item, _monitor) => {
               /**
                * can drop if
                * 1. user is logged
                * 2. target cell is not an inactive day
                * 3. if target technician already assigned, both assignments (dropped and target) are different
                * 4. if target technician already assigned, both assignedSchedule (dropped and target) are different
                * 5. both dates are the same
                * 6. target technician has the same planning mode as the schedule being dropped on
                * 7. target technician it trained or in training for line being dropped on
                * 8. if target technician already assigned, dropped technician is trained or in training for target assignment line
                */

               return validate([
                   new BooleanConstraint(state.isLogged, 'User is not logged'),
                   new BooleanConstraint(cellData.type !== 'inactiveDay', 'Target technician has an off day'),
                   new BooleanConstraint(!targetIsAssignment || !isSameAssigment(item.assignment, cellData.assignment), 'Target is assigned and both are the same assignment'),
                   new BooleanConstraint(!targetIsAssignment || !isSameAssignedSchedule(item.assignedSchedule, cellData.assignedSchedule), 'Target is assigned and both are the same assignedSchedule'),
                   new BooleanConstraint(DateUtils.dateEquals(item.assignedSchedule.day, cellData.day), 'Dates are different'),
                   new SamePlanningModeForTechnicianAndScheduleConstraint(item.assignedSchedule.scheduleId, cellData.technician),
                   new BooleanConstraint(TechnicianService.isTechnicianTrainedOrInTrainingForLine(cellData.technician, item.assignedSchedule.lineId), 'Target technician is not trained or in training for line'),
                   new BooleanConstraint(!targetIsAssignment || TechnicianService.isTechnicianTrainedOrInTrainingForLine(item.technician, cellData.assignedSchedule.lineId), 'Dropped is not trained or in training for line assigned to target Technician')
               ])
           },

           collect: monitor => ({
               canDrop: monitor.canDrop(),
               isOver: monitor.isOver(),
               draggedItem: monitor.getItem(),
               didDrop: monitor.didDrop(),
           }),
       })
   
       drag(drop(ref));
        
        additionalClassName = useMemo<string>(() => {
           if (draggedItem === null || didDrop) {
               return '';
           }
           if (canDrop) {
               return 'canDrop ';
           } else if (state.isLogged){
               return 'noDrop';
           }
       }, [draggedItem, didDrop, canDrop, state.isLogged])
    }


    return (
        <div className={'planningCellOperator '+additionalClassName} ref={ref} >
            <div className={'backdrop '+additionalClassName}/>
            <PlanningCellOperatorAssignment cellData={cellData} />
        </div>
    )
}
