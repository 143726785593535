import AssignedSchedule, {getAssignedScheduleId, isAssignedSchedule} from "./assignedSchedule.interface";
import Assignment, {getAssignmentId, isAssigment} from "./assignment.interface";
import Replacement, {getReplacementId, isReplacement} from "./replacement.interface";
import {isString} from "../../Generic/libraries/string";

export default interface IPlanningChange {
    id: number|null,
    frontId: string|null,
    details: object,
    originalDetails: object,
    type: ChangeType,
    objectType: ChangeObjectType
}

export enum ChangeType {
    Creation = "creation",
    Update = "update",
    Deletion = "deletion"
}

export enum ChangeObjectType {
    Assigned_Schedule = 'AssignedSchedule',
    Assignment = 'Assignment',
    Replacement = 'Replacement'
}

export interface AssignedSchedulePlanningChange extends IPlanningChange{
    details: Partial<AssignedSchedule>,
    originalDetails: AssignedSchedule
}

export interface AssignmentPlanningChange extends IPlanningChange{
    details: Partial<Assignment>,
    originalDetails: Assignment
    assignedScheduleId: number,
    assignedScheduleFrontId: string;
}

export interface ReplacementPlanningChange extends IPlanningChange{
    details: Partial<Replacement>,
    originalDetails: Replacement
}

export function isAssignedSchedulePlanningChange(elem: any): elem is AssignedSchedulePlanningChange {
    return 'originalDetails' in elem && isAssignedSchedule(elem.originalDetails);
}

export function isAssignmentPlanningChange(elem: any): elem is AssignmentPlanningChange {
    return 'originalDetails' in elem && isAssigment(elem.originalDetails);
}

export function isReplacementPlanningChange(elem: any): elem is ReplacementPlanningChange {
    return 'originalDetails' in elem && isReplacement(elem.originalDetails);
}

export function getPlanningChangeDetailId(change: IPlanningChange): string|number {
    if (isAssignedSchedulePlanningChange(change)) {
        return getAssignedScheduleId(change.originalDetails)
    }

    if (isAssignmentPlanningChange(change)) {
        return getAssignmentId(change.originalDetails)
    }

    if (isReplacementPlanningChange(change)){
        return getReplacementId(change.originalDetails)
    }

    return ''
}