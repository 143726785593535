import {Constraint} from "../../../Generic/validation/constraint.interface";
import {PlanningState} from "../../context/state";
import {findAllAssignmentsForDay} from "../../repository/State/stateAssignmentRepository";
import Assignment from "../../model/assignment.interface";
import {nonUniqueFilter} from "../../../Generic/libraries/array";
import {DateUtils} from "../../../Generic/libraries/dateManipulation";
import {PlanningContainer} from "../../context/container";
import Technician from "../../model/technician.interface";

type IncorrectAssignmentInfo = {
    technician: Technician,
    date: Date
}
export class MaxOneAssignmentPerTechnicianPerDayConstraint implements Constraint {
    _state: PlanningState
    errorMessage: string = 'Technicians are assigned twice on the same day : '
    _incorrectAssignments: {[k: string]: IncorrectAssignmentInfo} = {};

    constructor(state: PlanningState) {
        this._state = state
    }

    isValid(): boolean {
        let allTechniciansAssignedOnlyOncePerDay = true;
        
        this._state.week.days.forEach((d: Date) => {
            const allAssignments = findAllAssignmentsForDay(this._state, d);
            const allAssignedTechnicians = allAssignments.map((a: Assignment) => a.technicianId)
            const nonUniqueAssignedTechnicians = allAssignedTechnicians.filter(nonUniqueFilter)
            if (nonUniqueAssignedTechnicians.length !== 0) {
                allTechniciansAssignedOnlyOncePerDay = false;
                nonUniqueAssignedTechnicians.forEach((id: number) => {
                    this._incorrectAssignments[`${DateUtils.getDateDisplay(d, 'd-m-Y')}-${id}`] = {
                        technician: PlanningContainer.technicianRepository.findOne(id),
                        date: d
                    };
                })
            }
        })

        Object.values(this._incorrectAssignments).forEach((info, idx) => {
            if (idx > 0) {
                this.errorMessage += ' | ';
            }
            this.errorMessage += 'Day : '
              + DateUtils.getDateDisplay(info.date, 'd-m-Y') + ' : Technician : ' + info.technician.trigram;

        })

        return allTechniciansAssignedOnlyOncePerDay;
    }
}