import Assignment from "./assignment.interface";

export default interface AssignedSchedule {
    id: number | null,
    frontId: string | null,
    day: Date,
    scheduleId: number | null,
    lineId: number,
    assignments: Assignment[],
    preferenceCapacity: number,
    incompleteSolution: boolean
}

export function getAssignedScheduleId(a: AssignedSchedule): string|number {
    return a.id !== null ? a.id : a.frontId
}

export function isSameAssignedSchedule(a: AssignedSchedule, b: AssignedSchedule): boolean {
    return getAssignedScheduleId(a) === getAssignedScheduleId(b)
}

export function isAssignedSchedule(elem: any): elem is AssignedSchedule {
    return 'assignments' in elem && 'preferenceCapacity' in elem
}
