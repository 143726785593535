import {isString} from "lodash";

export type ComparatorFunction<T> = (a: T, b: T) => boolean

export function createTrueComparatorFunction<T>(comparator: string|ComparatorFunction<T>|null): ComparatorFunction<T> {
    if (comparator === null) {
        return (a: T, b: T) => a === b
    }
    else if (isString(comparator)){
        return (a: T, b: T) => comparator in a && comparator in b && a[comparator] === b[comparator]
    } else {
        return comparator
    }
}

export function createFalseComparatorFunction<T>(comparator: string|ComparatorFunction<T>|null): ComparatorFunction<T> {
    if (comparator === null) {
        return (a: T, b: T) => a !== b
    }
    else if (isString(comparator)){
        return (a: T, b: T) => comparator in a && comparator in b && a[comparator] !== b[comparator]
    } else {
        return (a: T, b:T) => !comparator(a,b)
    }
}