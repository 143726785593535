import {PlanningState} from "../state";
import Assignment from "../../model/assignment.interface";
import {UpdateState} from "./updateState.interface";
import AssignedSchedule from "../../model/assignedSchedule.interface";
import {createAssignmentPlanningChange} from "../../model/Factory/PlanningChangeFactory";
import {ChangeType} from "../../model/planningChange.interface";
import { updateAssignedSchedules } from "../../repository/State/stateAssignedSchedulesRepository";
import { addChanges } from "../../repository/State/stateChangesRepository";

export default function swapAssignments(state: PlanningState, assignmentA: Assignment, assignedScheduleA: AssignedSchedule, assignmentB: Assignment, assignedScheduleB: AssignedSchedule): UpdateState | null {
    /**
     * 1. swap assignedScheduleIds in both assignments
     * 2. swap assignments in AssignedSchedules
     * 3. create the changes for both Assignments
     */
    let updatedAssignmentA = {...assignmentA, assignedScheduleId: assignedScheduleB.id, assignedScheduleFrontId: assignedScheduleB.frontId}
    let updatedAssignmentB = {...assignmentB, assignedScheduleId: assignedScheduleA.id, assignedScheduleFrontId: assignedScheduleA.frontId}

    const updatedAssignedScheduleA = swapAssignmentsInAssignedSchedule(assignedScheduleA, updatedAssignmentA, updatedAssignmentB)
    const updatedAssignedScheduleB = swapAssignmentsInAssignedSchedule(assignedScheduleB, updatedAssignmentB, updatedAssignmentA)

    const updatedAssignedSchedules = updateAssignedSchedules(state, [updatedAssignedScheduleA, updatedAssignedScheduleB])

    const updatedChanges = addChanges(state, [
        createAssignmentPlanningChange(ChangeType.Update, updatedAssignmentA, assignmentA),
        createAssignmentPlanningChange(ChangeType.Update, updatedAssignmentB, assignmentB)
    ])
    
    return {
        changes: updatedChanges,
        assignedSchedules: updatedAssignedSchedules
    }
}

function swapAssignmentsInAssignedSchedule(assignedSchedule: AssignedSchedule, assignmentExisting: Assignment, assignmentToSwap: Assignment): AssignedSchedule {
    let updatedAssignments = assignedSchedule.assignments.map((a:Assignment) => {
        if (a.id == assignmentExisting.id && a.frontId === assignmentExisting.frontId) {
            return assignmentToSwap
        }
        return a
    })

    return {...assignedSchedule, assignments: updatedAssignments}
}