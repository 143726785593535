import {DataTransformer, InputDataTransformer} from "../../../Generic/dataTransformer/dataTransformer.interface";
import {PlanningState} from "../../context/state";
import {InputWeek, weekDataTransformer} from "./weekDataTransformer";
import {bulkTransform} from "../../../Generic/dataTransformer/bulkDataTransformer";
import {assignedScheduleDataTransformer} from "./assignedSchedulesDataTransformer";
import {initWeek} from "../week.interface";
import {demandWeekDataTransformer} from "./demandWeekDataTransformer";
import DemandWeek from "../demandWeek.interface";
import AssignedSchedule from "../assignedSchedule.interface";
import IPlanningChange, {ChangeType} from "../planningChange.interface";
import Technician from "../technician.interface";
import {AssignedScheduleFactory} from "../Factory/assignedScheduleFactory";
import {addElementToArrayBy} from "../../../Generic/libraries/array";
import Assignment from "../assignment.interface";
import {createAssignmentPlanningChange} from "../Factory/PlanningChangeFactory";

export interface InputState extends InputDataTransformer {
    isLogged: boolean,
    week: InputWeek,
    assignedSchedules: [],
    dayUpdated: boolean,
    demandWeek: object,
    print: boolean
}

export const stateDataTransformer: DataTransformer<PlanningState> = {

    transform: (inputState: InputState): PlanningState => {
        let transformedAssignedSchedules = inputState.assignedSchedules ? bulkTransform(assignedScheduleDataTransformer, inputState.assignedSchedules) : [];
        let changes = [];
        let incompleteSolution = false

        let demandWeek: DemandWeek = inputState.demandWeek !== null ? demandWeekDataTransformer.transform(inputState.demandWeek) : null

        //handle display of incomplete solution
        if (demandWeek && !demandWeek.solution.complete) {
            let {updatedChanges, updatedAssignedSchedules} = getIncompleteSolutionAssignedSchedulesAndChanges(demandWeek, transformedAssignedSchedules)
            transformedAssignedSchedules = updatedAssignedSchedules
            changes = updatedChanges
            incompleteSolution = true
        }

        return {
            initialized: true,
            isLogged: inputState.isLogged ?? false,
            week: inputState.week ? weekDataTransformer.transform(inputState.week) : initWeek(),
            assignedSchedules: transformedAssignedSchedules,
            changes,
            dayUpdated: inputState.dayUpdated ?? false,
            incompleteSolution,
            demandWeek,
            errors: null,
            print: inputState.print
        }
    }
}

type TempAssignedSchedule =  {assignedSchedule: AssignedSchedule, technicians: Technician[]}

function getIncompleteSolutionAssignedSchedulesAndChanges(demandWeek: DemandWeek, existingAssignedSchedules: AssignedSchedule[]): {updatedAssignedSchedules: AssignedSchedule[], updatedChanges: IPlanningChange[]} {
    let incompleteChanges: IPlanningChange[] = []
    let incompleteAssignedSchedules: AssignedSchedule[] = [...existingAssignedSchedules]

    let tempAssignedSchedules: { [k: number]: TempAssignedSchedule } = {}

    //define all the technicians per schedules
    demandWeek.solution.variables.forEach((variable) => {
        variable.assignedSchedule.incompleteSolution = true;

        if (!tempAssignedSchedules.hasOwnProperty(variable.assignedSchedule.id)){
            tempAssignedSchedules[variable.assignedSchedule.id] = {'assignedSchedule': variable.assignedSchedule, 'technicians' : []};
        }
        tempAssignedSchedules[variable.assignedSchedule.id]['technicians'].push(variable.technician);
    });

    //create assignments for each technicians and add the assignmentChanges to the changes list
    Object.values(tempAssignedSchedules).forEach((value: TempAssignedSchedule) => {
        let incompleteAssignedSchedule = AssignedScheduleFactory.createIncompleteAssignedSchedule(
            value.assignedSchedule, value.technicians
        )

        incompleteAssignedSchedules = addElementToArrayBy(incompleteAssignedSchedules, incompleteAssignedSchedule, 'id')
        incompleteAssignedSchedule.assignments.forEach((a: Assignment) => {
            incompleteChanges.push(createAssignmentPlanningChange(ChangeType.Creation, a, a))
        })
    })

    return {
        updatedAssignedSchedules: incompleteAssignedSchedules,
        updatedChanges: incompleteChanges
    }
}
