import BackendRepository from "../repository/backendRepository";
import LineRepository from "../repository/lineRepository";
import TechnicianRepository from "../repository/technicianRepository";
import ScheduleRepository from "../repository/scheduleRepository";
import CompetencyRepository from "../repository/competencyRepository";
import PlanningLocalStorage from "../repository/Storage/planningLocalStorage";
import {Validator} from "../../Generic/validation/validator.interface";
import {GenericValidator} from "../../Generic/validation/validator";

export interface IPlanningContainer {
    localStorage: PlanningLocalStorage,
    backendRepository: BackendRepository,
    lineRepository: LineRepository,
    technicianRepository: TechnicianRepository,
    scheduleRepository: ScheduleRepository,
    competencyRepository: CompetencyRepository,
    validator: Validator
}

const planningLocalStorage = new PlanningLocalStorage();
const backendRepository = new BackendRepository(planningLocalStorage);

export const PlanningContainer: IPlanningContainer = {
    localStorage: planningLocalStorage,
    backendRepository,
    lineRepository: new LineRepository(planningLocalStorage),
    technicianRepository: new TechnicianRepository(planningLocalStorage),
    scheduleRepository: new ScheduleRepository(planningLocalStorage),
    competencyRepository: new CompetencyRepository(planningLocalStorage),
    validator: new GenericValidator()
}