import {DataTransformer, InputDataTransformer} from "../../../Generic/dataTransformer/dataTransformer.interface";
import Technician, {WeekPlanningMode, TechnicianStatus} from "../technician.interface";
import {bulkTransform} from "../../../Generic/dataTransformer/bulkDataTransformer";
import {InputQualification, qualificationDataTransformer} from "./qualificationDataTransformer";
import { DateUtils } from "../../../Generic/libraries/dateManipulation";

export interface InputTechnician extends InputDataTransformer {
    id: number,
    trigram : string,
    qualifications: InputQualification[],
    status: string,
    active: boolean,
    currentPlanningMode: string,
    inactiveDays: {day: string}[]
}

export const technicianDataTransformer: DataTransformer<Technician> = {

    transform: (inputTechnician: InputTechnician): Technician => {
        return {
            id: inputTechnician.id,
            trigram: inputTechnician.trigram,
            inactiveDays: inputTechnician.inactiveDays.map((d: {day: string}) => DateUtils.setHoursToH(new Date(d.day), 12)),
            qualifications: bulkTransform(qualificationDataTransformer, inputTechnician.qualifications),
            status: inputTechnician.status as TechnicianStatus,
            planningMode: inputTechnician.currentPlanningMode as WeekPlanningMode,
            active: inputTechnician.active
        };
    }
}
