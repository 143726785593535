import {DataTransformer, InputDataTransformer} from "../../../Generic/dataTransformer/dataTransformer.interface";
import AssignedSchedule from "../assignedSchedule.interface";
import {InputLine} from "./lineDataTransformer";
import {InputSchedule} from "./scheduleDataTransformer";
import {InputWeek} from "./weekDataTransformer";
import {bulkTransform} from "../../../Generic/dataTransformer/bulkDataTransformer";
import {assignmentDataTransformer} from "./assignmentDataTransformer";
import { DateUtils } from "../../../Generic/libraries/dateManipulation";

export interface InputAssignedSchedule extends InputDataTransformer {
    id: number,
    assignedPreferenceCapacity: number,
    day: string,
    line: InputLine,
    schedule: InputSchedule,
    week: InputWeek,
    assignments: []
}

export const assignedScheduleDataTransformer: DataTransformer<AssignedSchedule> = {

    transform: (inputAssignedSchedule: InputAssignedSchedule): AssignedSchedule => {

        return {
            id: inputAssignedSchedule.id,
            frontId: null,
            day: DateUtils.setHoursToH(new Date(inputAssignedSchedule.day), 12),
            scheduleId: inputAssignedSchedule.schedule.id,
            lineId: inputAssignedSchedule.line.id,
            preferenceCapacity: inputAssignedSchedule.assignedPreferenceCapacity,
            assignments: bulkTransform(assignmentDataTransformer, inputAssignedSchedule.assignments),
           incompleteSolution: false
        };
    }
}
