import {PlanningState} from "../state";
import AssignedSchedule from "../../model/assignedSchedule.interface";
import {UpdateState} from "./updateState.interface";
import {createAssignedSchedulePlanningChange} from "../../model/Factory/PlanningChangeFactory";
import {ChangeType} from "../../model/planningChange.interface";
import { addChanges } from "../../repository/State/stateChangesRepository";
import { removeAssignedSchedules } from "../../repository/State/stateAssignedSchedulesRepository";

export function deleteAssignedSchedule(state: PlanningState, assignedSchedule: AssignedSchedule): UpdateState | null {
    let updatedChanges = addChanges(state, [createAssignedSchedulePlanningChange(ChangeType.Deletion, assignedSchedule, assignedSchedule)]) 
    let updatedAssignedSchedules = removeAssignedSchedules(state, [assignedSchedule]);

    return {
        changes: updatedChanges,
        assignedSchedules: updatedAssignedSchedules
    }
}