import {ChangeType} from "../../model/planningChange.interface";
import AssignedSchedule from "../../model/assignedSchedule.interface";
import {createAssignedSchedulePlanningChange} from "../../model/Factory/PlanningChangeFactory";
import {PlanningState} from "../state";
import {UpdateState} from "./updateState.interface";
import {addChanges} from "../../repository/State/stateChangesRepository";
import {findAllAssignedSchedulesForDay, removeAssignedSchedules} from "../../repository/State/stateAssignedSchedulesRepository";

export default function deleteDayAssignedSchedules(state: PlanningState, d: Date): UpdateState | null {
    //1. get all day assignments
    const existingDayAssignedSchedules: AssignedSchedule[] = findAllAssignedSchedulesForDay(state, d);

    if (existingDayAssignedSchedules.length === 0) {
        return null;
    }

    //2. delete all assignments
    const updatedAssignedSchedules = removeAssignedSchedules(state, existingDayAssignedSchedules)

    //3. Add all changes
    const updatedChanges = addChanges(state, existingDayAssignedSchedules.map((a: AssignedSchedule) => {
        return createAssignedSchedulePlanningChange(ChangeType.Deletion, a, a)
    }))

    return {
        changes: updatedChanges,
        assignedSchedules: updatedAssignedSchedules
    }
}