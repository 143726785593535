import {CommonReactTable} from "../common/commonReactTable";
import Technician from "../../model/technician.interface";
import {getDateToStringIndex} from "../../../Generic/formatter/dayFormatter";
import AssignedSchedule from "../../model/assignedSchedule.interface";
import Assignment from "../../model/assignment.interface";
import {DateUtils} from "../../../Generic/libraries/dateManipulation";
import {useContext, useMemo} from "react";
import {PlanningContext} from "../../context/PlanningContextProvider";
import {sortByFunction} from "../../../Generic/libraries/sort";
import {Column} from "react-table";
import useTranslation from "../../../Generic/hooks/useTranslation";
import {OperatorTechnicianCell} from "./operatorTechnicianCell";
import {PlanningCellOperator} from "./planningCellOperator";
import FullHeader from "../LinePlanning/fullHeader";
import {Loader} from "react-bulma-components";


type OperatorPlanningTableProps = {
    switchPlanningView?: () => void,
    technicians: Technician[],
}

export type TableDataTechnicianDay = {
    day: Date;
    assignment?: Assignment;
    assignedSchedule?: AssignedSchedule;
    technician: Technician;
    type: "inactiveDay" | "assignment" | "noAssignment";
};

/**
 * k in shape of indexDay YYYYMMDD (indexDay)
 */
type TableData = { technician: Technician } & {
    [k: string]: TableDataTechnicianDay;
};

export type OperatorColumn<T extends object = {}> = {
    headerClassName?: string;
    cellClassName?: string;
} & Column<T>;

export function OperatorPlanningTable({switchPlanningView, technicians}: OperatorPlanningTableProps) {
    const {state} = useContext(PlanningContext)
    const {trans} = useTranslation()

    const widths = {
        techColumnWidth: 75,
        dayColumnWidth: 150,
        columnBorder: 2,
    };

    /**
     * @var tableData TableData
     */
    const tableData = useMemo(() => {
        let data = technicians
                .filter((t: Technician) => t.active)
                .map((technician: Technician) => {
                let technicianData: { [k: string]: TableDataTechnicianDay } = {};

                //setup each days
                state.week.days.forEach((day: Date) => {
                    const dayIndex: string = getDateToStringIndex(day);
                    //add assigned days to technician
                    state.assignedSchedules.forEach(
                        (assignedSchedule: AssignedSchedule) => {
                            assignedSchedule.assignments.forEach(
                                (assignment: Assignment) => {
                                    if (
                                        assignment.technicianId ===
                                        technician.id &&
                                        DateUtils.dateEquals(
                                            day,
                                            assignment.day
                                        )
                                    ) {
                                        technicianData[dayIndex] = {
                                            type: "assignment",
                                            day,
                                            technician,
                                            assignedSchedule,
                                            assignment,
                                        };
                                    }
                                }
                            );
                        }
                    );

                    //add inactive days for technician
                    technician.inactiveDays.forEach((day: Date) => {
                        const dayIndex: string = getDateToStringIndex(day);
                        technicianData[dayIndex] = {
                            type: "inactiveDay",
                            technician,
                            day,
                        };
                    });

                    //add no assignment if none of the 2 above
                    if (!(dayIndex in technicianData)) {
                        technicianData[dayIndex] = {
                            type: "noAssignment",
                            day,
                            technician,
                        };
                    }
                });

                return {
                    technician,
                    ...technicianData,
                };
            });

        return sortByFunction(data, (td: TableData) => td.technician.trigram);
    }, [state, technicians]);

    const columns = useMemo<OperatorColumn[]>(() => {
        let techColumn: OperatorColumn[] = [
            {
                Header: !state.print ? (
                    <div
                        className="visualizationSwitcher"
                        onClick={switchPlanningView}
                    >
                        {trans(
                            "planning.switch.planning.view.label.fromTechnicians"
                        )}
                    </div>
                ): '',
                accessor: "technician",
                width: widths.techColumnWidth,
                Cell: ({ value }) => {
                    return <OperatorTechnicianCell technician={value} />;
                },
            },
        ];

        let daysColumns: OperatorColumn[] = state.week.days.map(
            (day: Date): OperatorColumn => {
                return {
                    Header: DateUtils.getDateDisplay(day, "D. d M"),
                    id: getDateToStringIndex(day),
                    accessor: getDateToStringIndex(day),
                    width: widths.dayColumnWidth,
                    Cell: (data) => {
                        return <PlanningCellOperator cellData={data.value} />;
                    },
                };
            }
        );

        const basicTable = {
            Header: (_row) => <FullHeader />,
            id: "fullHeader",
            minWidth: widths.techColumnWidth + 7 * widths.dayColumnWidth,
            width: widths.techColumnWidth + 7 * widths.dayColumnWidth,
        };

        const allDataColumns = [...techColumn, ...daysColumns];
        let columns: OperatorColumn[] = [];
        if (!state.initialized) {
            columns.push({
                ...basicTable,
                Cell: (_data) => (
                    <>
                        <Loader /> {trans("planning.table.loading")}
                    </>
                ),
                accessor: "noData",
            });
        } else {
            columns.push({
                ...basicTable,
                columns: allDataColumns,
            });
        }
        return columns;
    }, [state]);

    const getHeaderProps = (column) => {
        const columnId = column.originalId ?? column.id;
        let className = ''
        let style = {
            width: column.width
        }

        if (columnId === 'technician') {
            className= 'cornerHeader'
        } else if (columnId === 'fullHeader'){
            className = 'fullHeader'
        } else {
            className = 'dataHeader';

            if (state.print) {
                className+=' print';
            }
        }

        return {
            className,
            style
        }
    }

    const getCellProps = (cell) => {
        const columnId = cell.column.originalId ?? cell.column.id;
        let className = ''
        let style = {
            width: cell.column.width
        }

        if (columnId === 'technician') {
            className= 'indexCell bodyCell'
        } else {
            className = 'valueCell bodyCell'
        }

        return {
            className,
            style
        }
    }

    return (
        <CommonReactTable
            columns={columns}
            data={tableData}
            tableId='planningTable'
            getHeaderProps={getHeaderProps}
            getCellProps={getCellProps}
        />
    )
}
