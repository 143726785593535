import {Constraint} from "../constraint.interface";
import {isString} from "lodash";
import {
    ComparatorFunction,
    createFalseComparatorFunction
} from "../comparatorFunctionFactory";

export class NotSameConstraint<T> implements Constraint {
    _elementA : T
    _elementB: T
    _comparator: ComparatorFunction<T>
    errorMessage: string;

    constructor(elementA: T, elementB: T, trueComparator: string|ComparatorFunction<T>|null =  null, errorMessage: string = '') {
        this._elementA = elementA
        this._elementB = elementB
        this.errorMessage = errorMessage

        this._comparator = createFalseComparatorFunction(trueComparator)
    }

    isValid(): boolean {
        return this._comparator(this._elementA, this._elementB)
    }

}