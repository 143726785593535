import {Constraint} from "../../../Generic/validation/constraint.interface";
import Technician from "../../model/technician.interface";
import {PlanningContainer} from "../../context/container";
import {DateUtils} from "../../../Generic/libraries/dateManipulation";
import {isNumber} from "lodash";
import {SameConstraint} from "../../../Generic/validation/constraint/sameConstraint";

const Translator = require('../../../Generic/libraries/translator')

export class TechnicianAvailableOnTheDayConstraint implements Constraint {
    _technician: Technician
    _day: Date
    _errorMessage: string = 'planning.validation.technicianNotAvailableOnDay'

    constructor(technician: Technician|number, day: Date) {
        if (isNumber(technician)) {
            technician = PlanningContainer.technicianRepository.findOne(technician)
        }
        this._technician = technician
        this._day = day
    }

    isValid(): boolean {
        return this._technician.inactiveDays.filter((d: Date) => {
            return PlanningContainer.validator.validate([
                new SameConstraint(this._day, d, DateUtils.dateEquals)
            ])
        }).length === 0;
    }

    get errorMessage() {
        return Translator.trans(this._errorMessage, {
            'technicianTrigram': this._technician.trigram,
            'day': DateUtils.getDateDisplay(this._day, 'd-m-y')
        })
    }
}
