import {Constraint} from "../../../Generic/validation/constraint.interface";
import Technician from "../../model/technician.interface";
import AssignedSchedule from "../../model/assignedSchedule.interface";
import {PlanningContainer} from "../../context/container";
import {TechnicianService} from "../../model/Service/technicianService";
import Assignment from "../../model/assignment.interface";
import {isReplacement} from "../../model/replacement.interface";

export class AssignmentNotReplacedConstraint implements Constraint {
    _assignment: Assignment
    errorMessage: "The assignment is already replaced"

    constructor(assignment: Assignment) {
        this._assignment = assignment
    }

    isValid(): boolean {
        return !this._assignment.replaced
    }
}