import {Constraint} from "../../../Generic/validation/constraint.interface";
import Technician from "../../model/technician.interface";
import AssignedSchedule from "../../model/assignedSchedule.interface";
import {PlanningContainer} from "../../context/container";
import {TechnicianService} from "../../model/Service/technicianService";
import Assignment from "../../model/assignment.interface";
import {isReplacement} from "../../model/replacement.interface";
import Schedule from "../../model/schedule.interface";
import {PlanningState} from "../../context/state";
import {sprintf} from "../../../Generic/libraries/string";
import {DateUtils} from "../../../Generic/libraries/dateManipulation";
import {findAllAssignedSchedulesForDay} from "../../repository/State/stateAssignedSchedulesRepository";
import {isNumber} from "lodash";
import Line from "../../model/line.interface";
import {SameConstraint} from "../../../Generic/validation/constraint/sameConstraint";

const Translator = require('../../../Generic/libraries/translator')

export class ScheduleNotExistingForTheDayAndTheLineConstraint implements Constraint {
    _state: PlanningState
    _schedule: Schedule
    _line: Line
    _day: Date
    _errorMessage: string = 'planning.validation.scheduleAlreadyExistingOnDayAndLine'

    constructor(state: PlanningState, schedule: Schedule|number, line: Line|number, day: Date) {
        if (isNumber(schedule)) {
            schedule = PlanningContainer.scheduleRepository.findOne(schedule)
        }
        if (isNumber(line)) {
            line = PlanningContainer.lineRepository.findOne(line)
        }

        this._schedule = schedule
        this._line = line
        this._state = state
        this._day = day
    }

    isValid(): boolean {
        const dayAssignedSchedules = findAllAssignedSchedulesForDay(this._state, this._day)
        if (dayAssignedSchedules.length === 0) {
            return true
        }

        return dayAssignedSchedules.filter((a: AssignedSchedule) => {
            return PlanningContainer.validator.validate([
                new SameConstraint(a.scheduleId, this._schedule.id),
                new SameConstraint(a.lineId, this._line.id)
            ])
        }).length === 0
    }

    get errorMessage() {
        return Translator.trans(this._errorMessage, {
            'scheduleName': this._schedule.name,
            'day': DateUtils.getDateDisplay(this._day, 'd-m-y'),
            'lineName': this._line.name
        })
    }
}