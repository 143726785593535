import {Constraint} from "../../../Generic/validation/constraint.interface";
import Technician from "../../model/technician.interface";
import AssignedSchedule from "../../model/assignedSchedule.interface";
import {PlanningContainer} from "../../context/container";
import {TechnicianService} from "../../model/Service/technicianService";
import Assignment from "../../model/assignment.interface";
import {PlanningState} from "../../context/state";
import {sprintf} from "../../../Generic/libraries/string";
import {DateUtils} from "../../../Generic/libraries/dateManipulation";
import {findAllAssignedSchedulesForDay} from "../../repository/State/stateAssignedSchedulesRepository";
import {isNumber} from "lodash";
import {SameConstraint} from "../../../Generic/validation/constraint/sameConstraint";
import {findAllAssignmentsForDay} from "../../repository/State/stateAssignmentRepository";

const Translator = require('../../../Generic/libraries/translator')

export class TechnicianNotAssignedOnTheDayConstraint implements Constraint {
    _state: PlanningState
    _technician: Technician
    _day: Date
    _errorMessage: string = 'planning.validation.technicianAlreadyAssignedOnDay'

    constructor(state: PlanningState, technician: Technician|number, day: Date) {
        if (isNumber(technician)) {
            technician = PlanningContainer.technicianRepository.findOne(technician)
        }
        this._technician = technician
        this._day = day
        this._state = state
    }

    isValid(): boolean {
        const assignmentsForTheDay = findAllAssignmentsForDay(this._state, this._day)
        return assignmentsForTheDay.filter((a: Assignment) => {
            return PlanningContainer.validator.validate([
                new SameConstraint(this._technician.id, a.technicianId)
            ])
        }).length === 0;
    }

    get errorMessage() {
        return Translator.trans(this._errorMessage, {
            'technicianTrigram': this._technician.trigram,
            'day': DateUtils.getDateDisplay(this._day, 'd-m-y')
        })
    }
}