import {Constraint} from "../constraint.interface";

export class BooleanConstraint implements Constraint {
    _statement: boolean
    errorMessage: string;

    constructor(statement: boolean, errorMessage: string | null = null) {
        this._statement = statement;
        this.errorMessage = errorMessage ?? 'Incorrect statement'
    }
    isValid(): boolean {
        return this._statement;
    }

}